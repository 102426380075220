import states from '@/store/states.json'
import axios from 'axios'

const baseStoreModule = {
  state: {
    documentSearchDialog: false,
    loading: true,
    snackbarContent: {
      color: 'warning',
      message: null
    },
    alertContent: {
      type: 'warning',
      message: null
    },
    apiPageSize: 20,
    batchInProgress: false,
    config: null,
    lastInventoryOnHandLocation: window.localStorage.getItem('ioh-last-location-name')
  },
  getters: {
    getLastInventoryOnHandLocation: state => state.lastInventoryOnHandLocation,
    getAdvanceStatuses: state => state.config?.AdvanceStatus,
    getAllocationStatus: state => state.config?.AllocationStatus,
    getAssayResultStatus: state => state.config?.AssayResultStatus,
    getBrokerageRateModes: state => state.config?.BrokerageRateModes,
    getBusinessStatus: state => state.config?.BusinessStatus,
    getContainerTypes: state => state.config?.ContainerTypes,
    getConverterTypes: state => state.config?.ConverterTypes,
    getCurrencies: state => state.config?.Currencies,
    getCurrencyDefault: state => state.config?.CurrencyDefault,
    getCheckInStatuses: state => state.config?.CheckInStatuses,
    getLoadTypes: state => state.config?.LoadTypes,
    getLocations: state => state.config?.Locations,
    getLotStatuses: state => state.config?.LotStatuses,
    getLotTypes: state => state.config?.LotTypes,
    getMaterialDesignations: state => state.config?.MaterialDesignations,
    getMaterials: state => state.config?.Materials,
    getPaymentMethods: state => state.config?.PaymentMethods,
    getPaymentTypes: state => state.config?.PaymentTypes,
    getTransportMethods: state => state.config?.TransportMethods,
    getTransitStatuses: state => state.config?.TransitStatuses,
    getUnitsOfMeasure: state => state.config?.UnitsOfMeasure,
    getDocumentSearchDialog: state => state.documentSearchDialog,
    getStateList: () => states,
    snackbarContent: state => state.snackbarContent,
    alertContent: state => state.alertContent,
    loading: state => state.loading,
    apiPageSize: state => state.apiPageSize,
    batchInProgress: state => state.batchInProgress,
    configCurrencies: state => state.config?.Currencies,
    configCurrenciesFiltered (state) {
      const currencies = state.config?.Currencies
      if (!currencies) return currencies
      return currencies.filter(cur => cur.value !== 'NA')
    },
    configCurrenciesDefault: state => state.config?.CurrencyDefault,
    getConfigLocations: state => {
      if (state.config === null) return []
      return state.config['Locations']
    }
  },
  mutations: {
    setDocumentSearchDialog (state, value) {
      state.documentSearchDialog = value
    },
    setSnackbarSuccess: (state, message) => {
      state.snackbarContent = { color: 'primary', message }
    },
    setSnackbarError: (state, message) => {
      state.snackbarContent = { color: 'error', message }
    },
    setSnackbarInfo: (state, message) => {
      state.snackbarContent = { color: 'info', message }
    },
    setSnackbarWarning: (state, message) => {
      state.snackbarContent = { color: 'warning', message }
    },
    setAlertSuccess: (state, values) => {
      state.alertContent = { type: 'success', message: values.message, callback: values.callback }
    },
    setAlertError: (state, values) => {
      state.alertContent = { type: 'error', message: values.message, callback: values.callback }
    },
    setAlertInfo: (state, values) => {
      state.alertContent = { type: 'info', message: values.message, callback: values.callback }
    },
    setAlertWarning: (state, values) => {
      state.alertContent = { type: 'warning', message: values.message, callback: values.callback }
    },
    setLoading: (state, loading) => {
      state.loading = loading
    },
    setBatchInProgress (state, value) {
      state.batchInProgress = value
    },
    setConfig (state, config) {
      state.config = config
    },
    setLastInventoryOnHandLocation (state, value) {
      window.localStorage.setItem('ioh-last-location-name', value)
      state.lastInventoryOnHandLocation = value
    }
  },
  actions: {
    async fetchConfig ({ commit, state }) {
      // Only load once
      if (state.config === null) {
        const response = await axios.get('/api/config/')
        if (response.status === 200) {
          commit('setConfig', response.data)
        }
      }
      return state.config
    }
  }
}

export default baseStoreModule
