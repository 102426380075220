import Vue from 'vue'
import Vuex from 'vuex'

import advancedCalculatorStore from '@/store/modules/advancedCalculatorStore'
import allocationStore from './modules/allocationStore'
import authorizationStore from './modules/authorizationStore'
import baseStoreModule from '@/base/store'
import brokerStore from './modules/brokerStore'
import calendarStore from '@/store/modules/calendarStore'
import carrierStore from './modules/carrierStore'
import catalogStore from './modules/catalogStore'
import checkInHedgeBank from './modules/checkInHedgeBank'
import checkInSkidStore from './modules/checkInSkidStore'
import checkInStore from './modules/checkInStore'
import clientProformaStore from '@/store/modules/clientProformaStore'
import converterImageStore from './modules/converterImageStore'
import converterStore from './modules/converterStore'
import createConverterStore from './modules/createConverterStore'
import currentPurchaseStore from './modules/currentPurchaseStore'
import cutPlanStore from './modules/cutPlanStore'
import divisionStore from './modules/divisionStore'
import expenseStore from './modules/expenseStore'
import globalStore from './modules/globalStore'
import hedgeBankStore from './modules/hedgeBankStore'
import hedgeStore from './modules/hedgeStore'
import imageGroupStore from './modules/converterImageGroupStore'
import intakeBoxStore from './modules/intakeBoxStore'
import intakeStore from './modules/intakeStore'
import internalProformaStore from '@/store/modules/internalProformaStore'
import inventoryBoxStore from './modules/inventoryBoxStore'
import inventoryManifestStore from './modules/inventoryManifestStore'
import inventoryStore from './modules/inventoryStore'
import locationStore from './modules/locationStore'
import lotStore from './modules/lotStore'
import marketRateStore from './modules/marketRateStore'
import patchNotesStore from './modules/patchNotesStore'
import photoGroup from './modules/photoGroupStore'
import photoboothStore from './modules/photoboothStore'
import ppmCalculationStore from './modules/ppmCalculationStore'
import purchaseStore from './modules/purchaseStore'
import regionStore from './modules/regionStore'
import searchResultStore from './modules/searchResultStore'
import shipmentSummaryStore from './modules/shipmentSummaryStore'
import smelterStore from './modules/smelterStore'
import spendingReportStore from './modules/spendingReportStore'
import transitDashboardStore from './modules/TransitDashboard/transitDashboardStore'
import transitStore from './modules/transitStore'
import tripBlueprintStore from '@/store/modules/tripBlueprintStore'
import tripReportStore from '@/store/modules/tripReportStore'
import tripSaleStore from '@/store/modules/tripSaleStore'
import tripStore from '@/store/modules/tripStore'
import userStore from './modules/userStore'
import yardStore from './modules/yardStore'
import shipmentResultStore from '@/store/modules/shipmentResultStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    showFx: true,
    version: process.env.PACKAGE_VERSION || '0',
    allStates: [
      { value: 'AL', text: 'Alabama' },
      { value: 'AK', text: 'Alaska' },
      { value: 'AZ', text: 'Arizona' },
      { value: 'AR', text: 'Arkansas' },
      { value: 'CA', text: 'California' },
      { value: 'CO', text: 'Colorado' },
      { value: 'CT', text: 'Connecticut' },
      { value: 'DE', text: 'Delaware' },
      { value: 'FL', text: 'Florida' },
      { value: 'GA', text: 'Georgia' },
      { value: 'HI', text: 'Hawaii' },
      { value: 'ID', text: 'Idaho' },
      { value: 'IL', text: 'Illinois' },
      { value: 'IN', text: 'Indiana' },
      { value: 'IA', text: 'Iowa' },
      { value: 'KS', text: 'Kansas' },
      { value: 'KY', text: 'Kentucky' },
      { value: 'LA', text: 'Louisiana' },
      { value: 'ME', text: 'Maine' },
      { value: 'MD', text: 'Maryland' },
      { value: 'MA', text: 'Massachusetts' },
      { value: 'MI', text: 'Michigan' },
      { value: 'MN', text: 'Minnesota' },
      { value: 'MS', text: 'Mississippi' },
      { value: 'MO', text: 'Missouri' },
      { value: 'MT', text: 'Montana' },
      { value: 'NE', text: 'Nebraska' },
      { value: 'NV', text: 'Nevada' },
      { value: 'NH', text: 'New Hampshire' },
      { value: 'NJ', text: 'New Jersey' },
      { value: 'NM', text: 'New Mexico' },
      { value: 'NY', text: 'New York' },
      { value: 'NC', text: 'North Carolina' },
      { value: 'ND', text: 'North Dakota' },
      { value: 'OH', text: 'Ohio' },
      { value: 'OK', text: 'Oklahoma' },
      { value: 'OR', text: 'Oregon' },
      { value: 'PA', text: 'Pennsylvania' },
      { value: 'PR', text: 'Puerto Rico' },
      { value: 'RI', text: 'Rhode Island' },
      { value: 'SC', text: 'South Carolina' },
      { value: 'SD', text: 'South Dakota' },
      { value: 'TN', text: 'Tennessee' },
      { value: 'TX', text: 'Texas' },
      { value: 'UT', text: 'Utah' },
      { value: 'VT', text: 'Vermont' },
      { value: 'VA', text: 'Virginia' },
      { value: 'WA', text: 'Washington' },
      { value: 'WV', text: 'West Virginia' },
      { value: 'WI', text: 'Wisconsin' },
      { value: 'WY', text: 'Wyoming' }
    ]
  },
  getters: {
    getAppVersion: state => state.version,
    unitsOfMeasure: (state, getters, rootState, rootGetters) => rootGetters.getUnitsOfMeasure,
    converterTypes: (state, getters, rootState, rootGetters) => rootGetters.getConverterTypes,
    businessStatuses: (state, getters, rootState, rootGetters) => rootGetters.getBusinessStatus,
    stateList: state => state.allStates
  },
  modules: {
    advancedCalculatorStore,
    allocationStore,
    authorizationStore,
    baseStoreModule,
    brokerStore,
    carrierStore,
    calendarStore,
    catalogStore,
    checkIn: checkInStore,
    checkInSkidStore,
    checkInHedgeBank,
    clientProformaStore,
    converterStore,
    cutPlanStore,
    divisionStore,
    expenseStore,
    globalStore,
    hedgeStore,
    hedgeBank: hedgeBankStore,
    imageGroupStore,
    internalProformaStore,
    inventoryBoxStore,
    converterImageStore,
    createConverterStore,
    intakeStore,
    intakeBoxStore,
    inventoryManifestStore,
    inventoryStore,
    locationStore,
    lotStore,
    marketRate: marketRateStore,
    patchNotes: patchNotesStore,
    photoGroup,
    currentPurchases: currentPurchaseStore,
    purchaseStore,
    photoboothStore,
    ppmCalculationStore,
    regionStore,
    searchResultStore,
    shipmentResultStore,
    shipmentSummaryStore,
    smelterStore,
    spendingReportStore,
    transitDashboardStore,
    transitStore,
    tripBlueprintStore,
    tripReportStore,
    tripStore,
    tripSaleStore,
    userStore,
    yardStore
  }
})

export default store
