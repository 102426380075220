import moment from 'moment'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    // PROPERTIES
    isLocked: true,
    lotName: 'N/A',
    lotId: null,
    yardId: null,
    shipDate: null,
    convertersProcessed: 0,
    mixedCatalystWeight: 0,
    clientValueDate: null,
    arrivalDate: null,
    purchasedOutright: [
      // { materialType: 'AM', quantity: 2, price: 100, totalPrice: 200, paid: true },
      // { materialType: 'IF', quantity: 2, price: 100, totalPrice: 200, paid: true },
      // { materialType: 'DF', quantity: 2, price: 100, totalPrice: 200, paid: true },
      // { materialType: 'DP', quantity: 2, price: 100, totalPrice: 200, paid: true },
      // { materialType: 'D3', quantity: 2, price: 100, totalPrice: 200, paid: true }
    ],
    allocations: [],
    // Ounces available for the lot to allocate ( Total lot yield )
    containedPt: 0,
    containedPd: 0,
    containedRh: 0,
    brokeredRatePt: 0,
    brokeredRatePd: 0,
    brokeredRateRh: 0,
    // availablePt: 0,
    // availablePd: 0,
    // availableRh: 0,
    // FEES
    assayFee: 0,
    shreddingFee: 0,
    brokerageFee: 0,
    refiningFee: 0,
    processingFee: 0,
    handlingFee: 0,
    freightCharge: 0,
    netOtherFees: 0,
    consolidateFees: false,
    // Finance / Amount Due
    expectedPaymentDate: null,
    interestPercent: 0,
    depositPercent: 0,

    // ---NON Advanced MetaData---
    // Hedges Designated
    designatedHedges: [
      // {
      //   id: 1,
      //   ptRate: 1000,
      //   ptAvailableToz: 20,
      //   pdRate: 1000,
      //   pdAvailableToz: 10,
      //   rhRate: 2000,
      //   rhAvailableToz: 15
      // }
    ],
    brokerageTerms: {
      // 'id': 417,
      // 'url': 'http://localhost:8000/api/brokerage_terms/417/',
      // 'description': '',
      // 'yard': 'http://localhost:8000/api/yards/4437/',
      // 'pt_rate': '97.00',
      // 'pd_rate': '97.00',
      // 'rh_rate': '90.00',
      // 'treatment_charge': '1.40',
      // 'treatment_charge_fx': 1.4,
      // 'handling_fee': '500.00',
      // 'handling_fee_fx': 500.0,
      // 'finance_charge_percent': '0.00',
      // 'additional_terms': '',
      // 'ounce_payout_type': 'RE',
      // 'ounce_payout_type_text': 'Returned',
      // 'freight_responsibility': 'PG',
      // 'freight_responsibility_text': 'PGM',
      // 'refining_charge_pt_fx': '12.00',
      // 'refining_charge_pd_fx': '12.00',
      // 'refining_charge_rh_fx': '60.00',
      // 'minimum_volume_type': 'LBS',
      // 'minimum_volume_units': 0,
      // 'minimum_volume_lbs': 2000,
      // 'fx_currency': 'USD'
    },
    advances: [
      // {
      //   'id': 23,
      //   'status': 'OP',
      //   'status_text': 'Open',
      //   'lot': 2147,
      //   'lot_name': '1363 (Brandywine)',
      //   'amount': '49000.00',
      //   'amount_fx': 49000.0,
      //   'currency': 'USD',
      //   'currency_text': 'US Dollar',
      //   'payment_date': '2024-10-01',
      //   'created_for': 'e73054e4-70bb-46aa-a27b-1c8edb2572b1',
      //   'created_for_username': 'Chris Childers',
      //   'created_by': '904c2be4-011d-457b-92bd-c64721809216',
      //   'created_by_username': 'tremblayseb@aimfire.net',
      //   'created_at': '2024-10-01T06:22:01.720102-07:00',
      //   'updated_at': '2024-10-01T06:22:01.936967-07:00'
      // }
    ],
    yardChoices: [
      // {
      //   'id': 1,
      //   'name': 'Placeholder Name',
      //   'city': 'Sample City',
      //   'address': '123 Placeholder Street',
      //   'state': 'XX'
      // }
    ],
    advanceCalculatorHistory: [],
    historyVersion: null,
    pcToWeightConversionRate: 0,
    printPieces: false,
    printWeight: false
  },
  getters: {
    getIsLocked: (state) => state.isLocked,
    isModified: (state) => true,
    getLotName: (state) => state.lotName,
    getLotId: (state) => state.lotId,
    getYardId: (state) => state.yardId,
    getYardChoices: (state) => state.yardChoices,
    getShipDate: (state) => state.shipDate,
    getConvertersProcessed: (state) => state.convertersProcessed,
    getMixedCatalystWeight: (state) => state.mixedCatalystWeight,
    getArrivalDate: (state) => state.arrivalDate,
    getClientValueDate: (state) => state.clientValueDate,
    getClientValueDateByArrival: (state, getters) => {
      // Specify the date in the format "YYYY-MM-DD"
      if (getters.getArrivalDate) {
        let initialDate = new Date(getters.getArrivalDate)
        initialDate.setDate(initialDate.getDate() + getters.getTermsClientPaymentDays)
        return initialDate.toISOString().slice(0, 10)
      }
      return null
    },
    getPurchasedOutright: (state) => state.purchasedOutright,
    getGrossOutrightUnpaidValue (state) {
      return state.purchasedOutright.reduce((total, po) => po.paid ? total : total + +po.totalPrice, 0).toFixed(2)
    },
    getAllocations: (state, getters) => {
      return state.allocations.map(line => {
        line.ptRtoz = (Number(line.ptToz) * Number(line.ptRate / 100)).toFixed(3)
        line.ptValue = (Number(line.ptMarketRate) * line.ptRtoz).toFixed(2)
        line.pdRtoz = (Number(line.pdToz) * Number(line.pdRate / 100)).toFixed(3)
        line.pdValue = (Number(line.pdMarketRate) * line.pdRtoz).toFixed(2)
        line.rhRtoz = (Number(line.rhToz) * Number(line.rhRate / 100)).toFixed(3)
        line.rhValue = (Number(line.rhMarketRate) * line.rhRtoz).toFixed(2)
        return line
      })
    },
    getGrossMetalValue: (_, getters) => getters.getAllocations.reduce((v, a) => {
      return v + +a.ptValue + +a.pdValue + +a.rhValue
    }, 0).toFixed(3),
    getContainedPt: (state) => state.containedPt,
    getContainedPd: (state) => state.containedPd,
    getContainedRh: (state) => state.containedRh,
    getBrokeredRatePt: (state) => state.brokeredRatePt,
    getBrokeredRatePd: (state) => state.brokeredRatePd,
    getBrokeredRateRh: (state) => state.brokeredRateRh,
    getAvailablePt: (state, getters) => (+getters.getContainedPt * +getters.getBrokeredRatePt / 100).toFixed(3),
    getAvailablePd: (state, getters) => (+getters.getContainedPd * +getters.getBrokeredRatePd / 100).toFixed(3),
    getAvailableRh: (state, getters) => (+getters.getContainedRh * +getters.getBrokeredRateRh / 100).toFixed(3),
    getAllocatedPt (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.ptToz, 0).toFixed(3)
    },
    getAllocatedPd (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.pdToz, 0).toFixed(3)
    },
    getAllocatedRh (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.rhToz, 0).toFixed(3)
    },
    getUnallocatedPt (state, getters) {
      return (+getters.getAvailablePt - getters.getAllocatedPt).toFixed(3)
    },
    getUnallocatedPd (state, getters) {
      return (+getters.getAvailablePd - getters.getAllocatedPd).toFixed(3)
    },
    getUnallocatedRh (state, getters) {
      return (+getters.getAvailableRh - getters.getAllocatedRh).toFixed(3)
    },
    getAllocatedReturnablePt (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.ptRtoz, 0).toFixed(3)
    },
    getAllocatedReturnablePd (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.pdRtoz, 0).toFixed(3)
    },
    getAllocatedReturnableRh (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.rhRtoz, 0).toFixed(3)
    },
    getAllocatedReturnablePtValue (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.ptValue, 0).toFixed(3)
    },
    getAllocatedReturnablePdValue (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.pdValue, 0).toFixed(3)
    },
    getAllocatedReturnableRhValue (state, getters) {
      return getters.getAllocations.reduce((c, a) => c + +a.rhValue, 0).toFixed(3)
    },
    getTermsTreatmentCharge: (state) => {
      return Number(state.mixedCatalystWeight * state.brokerageTerms?.treatment_charge_fx || 0).toFixed(2)
    },
    getTermsHandlingFee: (state) => {
      return state.brokerageTerms?.handling_fee_fx || 0
    },
    getTermsRefiningFeePt: (state, getters) => {
      return (getters.getAvailablePt * state.brokerageTerms?.refining_charge_pt_fx || 0).toFixed(2)
    },
    getTermsRefiningFeePd: (state, getters) => {
      return (getters.getAvailablePd * state.brokerageTerms?.refining_charge_pd_fx || 0).toFixed(2)
    },
    getTermsRefiningFeeRh: (state, getters) => {
      return (getters.getAvailableRh * state.brokerageTerms?.refining_charge_rh_fx || 0).toFixed(2)
    },
    getTermsRefiningFeeTotal: (state, getters) => {
      return Number(+getters.getTermsRefiningFeePt + +getters.getTermsRefiningFeePd + +getters.getTermsRefiningFeeRh).toFixed(2)
    },
    getAssayFee: (state) => state.assayFee,
    getShreddingFee: (state) => state.shreddingFee,
    getTermShreddingFee: (state, getters) => {
      try {
        const fee = state?.brokerageTerms.shredding_fee_fx
        return (fee * getters.getConvertersProcessed).toFixed(2)
      } catch {
        return 0
      }
    },
    getTermsClientPaymentDays: (state) => {
      let days = 0
      if (state.brokerageTerms && state.brokerageTerms.client_payment_days) {
        return state.brokerageTerms.client_payment_days
      }
      return days
    },
    getBrokerageFee: (state) => state.brokerageFee,
    getTermBrokerageFee: (state, getters) => {
      try {
        const rate = state.brokerageTerms.brokerage_rate
        if (state.brokerageTerms.brokerage_rate_mode === 'GM') {
          return (getters.getGrossMetalValue * rate).toFixed(2)
        }
        if (state.brokerageTerms.brokerage_rate_mode === 'NM') {
          return (getters.getNetValue * rate).toFixed(2)
        }
      } catch {
        // one of the required props is missing so pass
      }
      return 0
    },
    getRefiningFee: (state) => state.refiningFee,
    getProcessingFee: (state) => state.processingFee,
    getHandlingFee: (state) => state.handlingFee,
    getFreightCharge: (state) => state.freightCharge,
    getNetOtherFees: (state) => state.netOtherFees,
    getTotalFees (state) {
      return (+state.refiningFee + +state.processingFee + +state.handlingFee + +state.freightCharge +
        +state.assayFee + +state.shreddingFee + +state.netOtherFees).toFixed(2)
    },
    // Net Value excluding the Brokerage Fee
    getNetValue (_, getters) {
      return (+getters.getGrossOutrightUnpaidValue + +getters.getGrossMetalValue - +getters.getTotalFees).toFixed(2)
    },
    getNetValueLessBrokerage (_, getters) {
      return (+getters.getGrossOutrightUnpaidValue + +getters.getGrossMetalValue - +getters.getTotalFees - +getters.getBrokerageFee).toFixed(2)
    },
    getConsolidateFees: (state) => state.consolidateFees,
    getExpectedPaymentDate: (state) => state.expectedPaymentDate,
    getDaysOfInterest: (state) => {
      // client value date - expected payment date
      if (state.clientValueDate && state.expectedPaymentDate) {
        try {
          const momentDate1 = moment(state.clientValueDate, 'YYYY-MM-DD')
          const momentDate2 = moment(state.expectedPaymentDate, 'YYYY-MM-DD')
          const days = momentDate1.diff(momentDate2, 'days')
          return (days < 0) ? 0 : days
        } catch (e) {
          console.error('Unable to parse dates for days of interest')
          console.error(e)
        }
      }
      return 0
    },
    getInterestPercent: (state) => state.interestPercent,
    getFinanceCharge: (state, getters) => {
      // net_value x interest_rate / 360 * days
      const days = getters.getDaysOfInterest
      if (days === 0) return 0
      return (getters.getNetValueLessBrokerage * (getters.getInterestPercent / 100) / 360 * getters.getDaysOfInterest).toFixed(2)
    },
    getDepositPercent: (state) => state.depositPercent,
    getSettlementAmountDue: (state, getters) => {
      return (+getters.getNetValueLessBrokerage - +getters.getFinanceCharge).toFixed(2)
    },
    getFinalSettlementAmountDue: (state, getters) => {
      const advanceTotal = state.advances.reduce((c, advance) => c + advance.amount_fx, 0)
      return getters.getSettlementAmountDue - advanceTotal
    },
    getDepositTotal: (state, getters) => {
      if (getters.getDepositPercent === 0) return 0
      const deposit = getters.getSettlementAmountDue * (getters.getDepositPercent / 100)
      return (Math.floor(deposit / 1000) * 1000).toFixed(0)
    },
    getUnallocatedHedges: (state) => {
      // return only hedges that are not already allocated
      const hedgeIds = state.allocations.map(a => a.hedgeId)
      return state.designatedHedges.filter(dh => !hedgeIds.includes(dh.id))
    },
    getCleanedBrokerageTerms: (state, getters) => {
      const cleaned = []
      if (!state.brokerageTerms) return cleaned
      cleaned.push(['Description', state.brokerageTerms.description])
      cleaned.push(['Pt Rate', state.brokerageTerms.pt_rate])
      cleaned.push(['Pd Rate', state.brokerageTerms.pd_rate])
      cleaned.push(['Rh Rate', state.brokerageTerms.rh_rate])
      cleaned.push(['Treatment Charge $/lb', state.brokerageTerms.treatment_charge_fx, getters.getTermsTreatmentCharge])
      cleaned.push(['Handling Fee', '', getters.getTermsHandlingFee])
      cleaned.push(['Finance Charge', state.brokerageTerms.finance_charge_percent + '%'])
      cleaned.push(['Ounce Payout Type', state.brokerageTerms.ounce_payout_type_text])
      cleaned.push(['Freight Responsibility', state.brokerageTerms.freight_responsibility_text])
      cleaned.push(['Refining Charge Pt per toz', state.brokerageTerms.refining_charge_pt_fx, getters.getTermsRefiningFeePt])
      cleaned.push(['Refining Charge Pd per toz', state.brokerageTerms.refining_charge_pd_fx, getters.getTermsRefiningFeePd])
      cleaned.push(['Refining Charge Rh per toz', state.brokerageTerms.refining_charge_rh_fx, getters.getTermsRefiningFeeRh])

      cleaned.push(['Shredding Fee Per Piece', state.brokerageTerms.shredding_fee_fx, getters.getTermShreddingFee])
      let br = ['Brokerage Rate Percent - ' + state.brokerageTerms.brokerage_rate_mode_text]
      if (state.brokerageTerms.brokerage_rate_mode !== 'NA') {
        br.push((state.brokerageTerms.brokerage_rate * 100) + '%')
        br.push(getters.getTermBrokerageFee)
      }
      cleaned.push(br)
      cleaned.push(['Client Payment Days', state.brokerageTerms.client_payment_days])

      if (state.brokerageTerms.minimum_volume_type === 'UNT') {
        cleaned.push(['Minimum Volume Units', state.brokerageTerms.minimum_volume_units])
      } else if (state.brokerageTerms.minimum_volume_type === 'LBS') {
        cleaned.push(['Minimum Volume Lbs', state.brokerageTerms.minimum_volume_lbs])
      }

      cleaned.push(['Additional Terms', state.brokerageTerms.additional_terms])
      return cleaned
    },
    getPtTermRate: (state) => state.brokerageTerms?.pt_rate,
    getPdTermRate: (state) => state.brokerageTerms?.pd_rate,
    getRhTermRate: (state) => state.brokerageTerms?.rh_rate,
    getAdvances: (state) => state.advances,
    totalAdvances: (state) => {
      let total = 0
      state.advances.forEach(advance => {
        total += +advance.amount_fx
      })
      return total.toFixed(2)
    },
    getRecommendedAdvance (state, getters) {
      return Number(getters.getDepositTotal - getters.totalAdvances).toFixed(2)
    },
    getAdvanceCalculatorHistory: (state) => state.advanceCalculatorHistory,
    getHistoryVersion: (state) => state.historyVersion,
    getStateSnapshot: (state) => {
      return {
        lot: state.lotId,
        data: {
          lotName: state.lotName,
          lotId: state.lotId,
          yardId: state.yardId,
          shipDate: state.shipDate,
          convertersProcessed: state.convertersProcessed,
          mixedCatalystWeight: state.mixedCatalystWeight,
          arrivalDate: state.arrivalDate,
          clientValueDate: state.clientValueDate,
          purchasedOutright: state.purchasedOutright,
          allocations: state.allocations,
          containedPt: state.containedPt,
          containedPd: state.containedPd,
          containedRh: state.containedRh,
          brokeredRatePt: state.brokeredRatePt,
          brokeredRatePd: state.brokeredRatePd,
          brokeredRateRh: state.brokeredRateRh,
          // availablePt: state.availablePt,
          // availablePd: state.availablePd,
          // availableRh: state.availableRh,
          assayFee: state.assayFee,
          shreddingFee: state.shreddingFee,
          brokerageFee: state.brokerageFee,
          refiningFee: state.refiningFee,
          processingFee: state.processingFee,
          handlingFee: state.handlingFee,
          freightCharge: state.freightCharge,
          netOtherFees: state.netOtherFees,
          consolidateFees: state.consolidateFees,
          expectedPaymentDate: state.expectedPaymentDate,
          interestPercent: state.interestPercent,
          depositPercent: state.depositPercent,
          brokerageTerms: state.brokerageTerms,
          yardChoices: state.yardChoices,
          pcToWeightConversionRate: state.pcToWeightConversionRate,
          printPieces: state.printPieces,
          printWeight: state.printWeight
        }
      }
    },
    getPcToWeightConversionRate: (state) => {
      return Number(state.pcToWeightConversionRate).toFixed(2)
    },
    getEstimatedWeightFromPieces: (state) => {
      return (Number(state.pcToWeightConversionRate) * Number(state.convertersProcessed)).toFixed(2)
    },
    getPrintPieces: (state) => state.printPieces,
    getPrintWeight: (state) => state.printWeight
  },
  mutations: {
    setIsLocked (state, value) {
      state.isLocked = value
    },
    clearState (state) {
      state.lotName = 'N/A'
      state.lotId = null
      state.yardId = null
      state.shipDate = null
      state.convertersProcessed = 0
      state.mixedCatalystWeight = 0
      state.arrivalDate = null
      state.clientValueDate = null
      state.purchasedOutright = []
      state.allocations = []
      state.containedPt = 0
      state.containedPd = 0
      state.containedRh = 0
      state.brokeredRatePt = 0
      state.brokeredRatePd = 0
      state.brokeredRateRh = 0
      // state.availablePt = 0
      // state.availablePd = 0
      // state.availableRh = 0
      state.assayFee = 0
      state.shreddingFee = 0
      state.brokerageFee = 0
      state.processingFee = 0
      state.handlingFee = 0
      state.freightCharge = 0
      state.netOtherFees = 0
      state.consolidateFees = false
      state.expectedPaymentDate = null
      state.interestPercent = 0
      state.depositPercent = 0
      state.designatedHedges = []
      state.brokerageTerms = {}
      state.advances = []
      state.yardChoices = []
      state.advanceCalculatorHistory = []
      state.historyVersion = null
      state.printPieces = false
      state.printWeight = false
    },
    setLotName (state, value) {
      state.lotName = value
    },
    setYardId (state, value) {
      state.yardId = value
    },
    setYardChoices (state, value) {
      state.yardChoices = value
    },
    setShipDate (state, value) {
      state.shipDate = value
    },
    setConvertersProcessed (state, value) {
      state.convertersProcessed = value
    },
    setMixedCatalystWeight (state, value) {
      state.mixedCatalystWeight = value
    },
    setArrivalDate (state, value) {
      state.arrivalDate = value
    },
    setClientValueDate (state, value) {
      state.clientValueDate = value
    },
    setPurchasedOutright (state, lines) {
      state.purchasedOutright = lines.map(line => {
        line.totalPrice = (Number(line.price) * Number(line.quantity)).toFixed(2)
        return line
      })
    },
    addPurchasedOutrightCode (state, materialTypeCode) {
      const newLine = { materialType: materialTypeCode, quantity: 0, price: 0, totalPrice: 0, paid: true }
      state.purchasedOutright = [...state.purchasedOutright, newLine]
    },
    setAllocations (state, value) {
      state.allocations = value
    },
    setContainedPt (state, value) {
      state.containedPt = value
    },
    setContainedPd (state, value) {
      state.containedPd = value
    },
    setContainedRh (state, value) {
      state.containedRh = value
    },
    setBrokeredRatePt (state, value) {
      state.brokeredRatePt = value
    },
    setBrokeredRatePd (state, value) {
      state.brokeredRatePd = value
    },
    setBrokeredRateRh (state, value) {
      state.brokeredRateRh = value
    },
    // setAvailablePt (state, value) {
    //   state.availablePt = value
    // },
    // setAvailablePd (state, value) {
    //   state.availablePd = value
    // },
    // setAvailableRh (state, value) {
    //   state.availableRh = value
    // },
    setAssayFee (state, value) {
      state.assayFee = value
    },
    setShreddingFee (state, value) {
      state.shreddingFee = value
    },
    setBrokerageFee (state, value) {
      state.brokerageFee = value
    },
    setRefiningFee (state, value) {
      state.refiningFee = value
    },
    setProcessingFee (state, value) {
      state.processingFee = value
    },
    setHandlingFee (state, value) {
      state.handlingFee = value
    },
    setFreightCharge (state, value) {
      state.freightCharge = value
    },
    setNetOtherFees (state, value) {
      state.netOtherFees = value
    },
    setConsolidateFees (state, value) {
      state.consolidateFees = value
    },
    setExpectedPaymentDate (state, value) {
      state.expectedPaymentDate = value
    },
    setInterestPercent (state, value) {
      state.interestPercent = value
    },
    setDepositPercent (state, value) {
      state.depositPercent = value
    },
    addHedgeToAllocations (state, line) {
      const allocations = state.allocations
      if (state.brokerageTerms.pt_rate) line.ptRate = state.brokerageTerms.pt_rate
      if (state.brokerageTerms.pd_rate) line.pdRate = state.brokerageTerms.pd_rate
      if (state.brokerageTerms.rh_rate) line.rhRate = state.brokerageTerms.rh_rate
      allocations.push(line)
      state.allocations = allocations
    },
    removeAllocation (state, hedgeId) {
      state.allocations = state.allocations.filter(allocation => allocation.hedgeId !== hedgeId)
    },
    setBrokerageTerms (state, value) {
      state.brokerageTerms = value
    },
    setAdvances (state, advances) {
      state.advances = advances
    },
    setAdvanceCalculatorHistory (state, calculatorHistoryList) {
      state.advanceCalculatorHistory = calculatorHistoryList
    },
    // sets the local variables based on a history entry
    setContextFromHistory (state, value) {
      if ('data_fx' in value) {
        state.historyVersion = value.id
        // set the state environments
        const props = [
          'yardChoices',
          'lotName',
          'lotId',
          'yardId',
          'shipDate',
          'convertersProcessed',
          'mixedCatalystWeight',
          'arrivalDate',
          'clientValueDate',
          'purchasedOutright',
          'allocations',
          'containedPt',
          'containedPd',
          'containedRh',
          'brokeredRatePt',
          'brokeredRatePd',
          'brokeredRateRh',
          // 'availablePt',
          // 'availablePd',
          // 'availableRh',
          'assayFee',
          'shreddingFee',
          'brokerageFee',
          'refiningFee',
          'processingFee',
          'handlingFee',
          'freightCharge',
          'netOtherFees',
          'consolidateFees',
          'expectedPaymentDate',
          'interestPercent',
          'depositPercent',
          'brokerageTerms',
          'printPieces',
          'printWeight'
        ]
        for (const prop of props) {
          if (prop in value.data_fx) state[prop] = value.data_fx[prop]
        }
      }
    },
    setLotId (state, value) {
      state.lotId = value
    },
    setHistoryVersionId (state, value) {
      state.historyVersion = value
    },
    setDesignatedHedges: (state, hedges) => {
      state.designatedHedges = hedges.map(hedge => {
        hedge.ptRate.toFixed(3)
        hedge.pdRate.toFixed(3)
        hedge.rhRate.toFixed(3)
        return hedge
      })
    },
    setPrintPieces: (state, value) => {
      state.printPieces = value
    },
    setPrintWeight: (state, value) => {
      state.printWeight = value
    }
  },
  actions: {
    // Gets the current advance calculator context and saves a new entry
    async saveAdvanceCalculator ({ commit, state, getters }) {
      const payload = getters.getStateSnapshot
      const response = await axios.post('/api/client_proformas/', payload)
      if (response.data) {
        commit('setAdvanceCalculatorHistory', [response.data, ...state.advanceCalculatorHistory])
        commit('setHistoryVersionId', response.data.id)
      }
    },
    async fetchAdvanceCalculators ({ commit, state }) {
      const response = await axios.get('/api/client_proformas/', { params: { lot: state.lotId } })
      commit('setAdvanceCalculatorHistory', response.data.results)
    },
    async fetchDesignatedHedges ({ commit, state }) {
      const response = await axios.get(`/api/lots/${state.lotId}/linked_hedges/`)
      commit('setDesignatedHedges', response.data.map(hedge => ({
        id: hedge.id,
        ptRate: hedge.pt_rate_per_toz_fx,
        ptAvailableToz: hedge.pt_available_toz,
        pdRate: hedge.pd_rate_per_toz_fx,
        pdAvailableToz: hedge.pd_available_toz,
        rhRate: hedge.rh_rate_per_toz_fx,
        rhAvailableToz: hedge.rh_available_toz
      })))
    },
    async fetchAdvances ({ commit, state }) {
      const response = await axios.get(`/api/advances/?ordering=id&fx_currency=USD`, {
        params: {
          lot: state.lotId,
          page_size: 0
        }
      })
      if (response.data) commit('setAdvances', response.data)
    },
    async fetchYardTerms ({ commit, state }) {
      if (!state.yardId) return
      const response = await axios.get(`/api/yards/${state.yardId}/`, { params: { fields: 'brokerageterm' } })
      if (response.data?.brokerageterm) {
        commit('setBrokerageTerms', response.data.brokerageterm)
      } else {
        commit('setSnackbarWarning', 'No Brokerage Terms Available', { root: true })
      }
    },
    async refreshDataFromLotData ({ commit, state, dispatch }) {
      // Fetch lot seed data to populate the report fields
      // LOT ID should already be set
      const response = await axios.get(`/api/lots/${state.lotId}/client_proforma_seed/`)
      if (response.data) {
        const rd = response.data
        if ('yardChoices' in rd) commit('setYardChoices', rd.yardChoices)

        if ('lotName' in rd) commit('setLotName', rd.lotName)
        if ('yardId' in rd) commit('setYardId', rd.yardId)
        if ('shipDate' in rd) commit('setShipDate', rd.shipDate)
        if ('convertersProcessed' in rd) commit('setConvertersProcessed', rd.convertersProcessed)
        if ('mixedCatalystWeight' in rd) commit('setMixedCatalystWeight', rd.mixedCatalystWeight)

        if ('arrivalDate' in rd) commit('setArrivalDate', rd.arrivalDate)
        if ('clientValueDate' in rd) commit('setClientValueDate', rd.clientValueDate)

        if ('purchasedOutright' in rd) commit('setPurchasedOutright', rd.purchasedOutright)
        // if ('allocations' in rd) commit('setAllocations', rd.allocations)
        if ('containedPt' in rd) commit('setContainedPt', rd.containedPt)
        if ('containedPd' in rd) commit('setContainedPd', rd.containedPd)
        if ('containedRh' in rd) commit('setContainedRh', rd.containedRh)
        if ('brokeredRatePt' in rd) commit('setBrokeredRatePt', rd.brokeredRatePt)
        if ('brokeredRatePd' in rd) commit('setBrokeredRatePd', rd.brokeredRatePd)
        if ('brokeredRateRh' in rd) commit('setBrokeredRateRh', rd.brokeredRateRh)
        // if ('availablePt' in rd) commit('setAvailablePt', rd.availablePt)
        // if ('availablePd' in rd) commit('setAvailablePd', rd.availablePd)
        // if ('availableRh' in rd) commit('setAvailableRh', rd.availableRh)
        if ('assayFee' in rd) commit('setAssayFee', rd.assayFee)
        if ('shreddingFee' in rd) commit('setShreddingFee', rd.shreddingFee)
        if ('brokerageFee' in rd) commit('setBrokerageFee', rd.brokerageFee)
        if ('refiningFee' in rd) commit('setRefiningFee', rd.refiningFee)
        if ('processingFee' in rd) commit('setProcessingFee', rd.processingFee)
        if ('handlingFee' in rd) commit('setHandlingFee', rd.handlingFee)
        if ('freightCharge' in rd) commit('setFreightCharge', rd.freightCharge)
        if ('netOtherFees' in rd) commit('setNetOtherFees', rd.netOtherFees)
        if ('consolidateFees' in rd) commit('setConsolidateFees', rd.consolidateFees)
        if ('expectedPaymentDate' in rd) commit('setExpectedPaymentDate', rd.expectedPaymentDate)
        if ('interestPercent' in rd) commit('setInterestPercent', rd.interestPercent)
        if ('depositPercent' in rd) commit('setDepositPercent', rd.depositPercent)
        if ('pcToWeightConversionRate' in rd) commit('setPcToWeightConversionRate', rd.pcToWeightConversionRate)

        // Non History Maintenance ( advances do not change )
        commit('setHistoryVersionId', null)
        // These are normally automatically fetched when a yardId is changed in the view - no change no fetch
        dispatch('fetchYardTerms')
      }
    }
  }
}
