<template>
  <unauthorized
    :authorized="$store.getters.can('view-ppm')"
    message="You are not authorized to view PPM Calculation"
  >
    <v-container
      v-if="!loading"
      class="pb-12"
      fluid
    >
      <v-alert
        v-if="summariesZeroPieces.length"
        type="warning"
        color="warning"
        outlined
      >
        The attached lot{{ summariesZeroPieces.length > 1 ? 's' : '' }} {{ summariesZeroPieces.join(', ') }} are indicated to have zero pieces.
      </v-alert>
      <url-breadcrumbs
        v-if="ppmCalculation"
        :items="[
          ['PPM calculations', { name: 'ppm-calculation-list' }],
          ppmCalculation.url
        ]"
      />
      <base-title title="PPM Calculator">
        <template slot="subtitle">
          {{ ppmCalculation.name }}
        </template>
      </base-title>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Current Values
            </v-card-title>
            <v-card-text>
              <ppm-values
                :foils-calc="ppmCalculation.foils_only"
                :ppm-calculation="ppmCalculation"
              />
            </v-card-text>
            <v-btn
              color="primary"
              fab
              absolute
              bottom
              right
              small
              @click="calculatorDialog = true"
            >
              <v-icon>mdi-calculator</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title>
              Meta
            </v-card-title>
            <ppm-meta
              :ppm-calculation="ppmCalculation"
            />
            <v-btn
              color="primary"
              absolute
              bottom
              right
              small
              fab
              @click="metaDialog = true"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-card>
          <v-card
            :disabled="!ppmCalculation.is_manual"
            class="mt-6"
          >
            <v-card-title>
              Manual
            </v-card-title>
            <v-card-subtitle>
              Clicking on the pencil button will allow you to update the values directly
            </v-card-subtitle>
            <v-btn
              v-if="ppmCalculation.is_manual"
              color="primary"
              small
              fab
              absolute
              bottom
              right
              @click="manualDialog = true"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card :disabled="ppmCalculation.is_manual">
            <v-card-title>
              Filters
            </v-card-title>
            <v-card-subtitle>
              This Calculator is currently set to manual and is not using filters
            </v-card-subtitle>
            <filters-card
              :ppm-calculation="ppmCalculation"
            />
            <v-btn
              v-if="!ppmCalculation.is_manual"
              color="primary"
              small
              fab
              absolute
              bottom
              right
              @click="filterDialog = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card :disabled="ppmCalculation.is_manual">
            <v-card-title>
              Lots
            </v-card-title>
            <v-card-text>
              <lots-table
                :lot-summaries="ppmCalculation.lot_summaries"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ppm-calc-skeleton v-else />
    <ppm-edit-meta
      v-model="metaDialog"
      :ppm-calculation="ppmCalculation"
    />
    <calculator-dialog
      v-model="calculatorDialog"
      :ppm-calculation="ppmCalculation"
    />
    <filters-dialog
      v-model="filterDialog"
      :ppm-calc="this.ppmCalculation"
    />
    <manual-ppm-dialog
      v-model="manualDialog"
      :ppm-calculation="ppmCalculation"
    />
  </unauthorized>
</template>

<script>
import FiltersCard from './components/FiltersCard'
import LotsTable from './components/LotsTable'
import FiltersDialog from './components/FiltersDialog'
import CalculatorDialog from './components/CalculatorDialog'
import { mapGetters } from 'vuex'
import PpmValues from './components/PpmValues'
import PpmMeta from './components/PpmMeta'
import ManualPpmDialog from './components/ManualPpmDialog'
import PpmCalcSkeleton from './components/PpmCalcSkeleton'
import PpmEditMeta from './components/PpmEditMeta'

export default {
  name: 'PpmCalc',
  components: {
    PpmEditMeta,
    PpmCalcSkeleton,
    ManualPpmDialog,
    PpmMeta,
    PpmValues,
    FiltersCard,
    LotsTable,
    FiltersDialog,
    CalculatorDialog
  },
  data: () => ({
    loading: true,
    metaDialog: false,
    filterDialog: false,
    calculatorDialog: false,
    manualDialog: false,
    ppmLots: []
  }),
  computed: {
    ...mapGetters({
      ppmCalculation: 'ppmCalculationStore/ppmCalculation'
    }),
    summariesZeroPieces () {
      return this.ppmCalculation.lot_summaries.filter(summary => summary.lot_pieces === 0).map(summary => summary.lot_name)
    }
  },
  methods: {
    // Initial page load & fetches ppm yard if applicable to show name in filters card
    async fetchRequirements () {
      this.loading = true
      await this.$store.dispatch('ppmCalculationStore/fetchPpmCalculation', this.$route.params.id)
      this.loading = false
    }
  },
  mounted () {
    this.fetchRequirements()
  }
}
</script>
