<template>
  <unauthorized
    :authorized="$store.getters.can('view-shipment-result')"
    message="You are not authorized to view Shipment Results"
  >
    <url-breadcrumbs
      v-if="breadcrumbs"
      :items="breadcrumbs"
    />
    <div v-if="error">
      {{ error }}
    </div>
    <brokered-settlement-skeleton
      v-else-if="loading"
      title="Shipment Result"
    />
    <v-card
      v-else
      class="pa-2 mx-auto"
      flat
      max-width="1000px"
    >
      <v-row>
        <v-col
          class="text-left"
          cols="3"
        >
          <v-img
            :src="config.logo"
            :alt="config.name"
            height="100px"
            contain
          />
        </v-col>
        <v-col
          class="text-center mt-4"
          cols="6"
        >
          <div class="text-h4 font-weight-bold">
            Shipment Results
            <br>
            <span class="text-h5">Confidential</span>
          </div>
          <div class="text-h6">
            {{ yardName }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
        >
          <table>
            <tbody>
              <tr>
                <td class="font-weight-bold">
                  Customer:
                </td>
                <td class="pl-2">
                  {{ yardName }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Lot ID:
                </td>
                <td class="pl-2">
                  {{ lotId }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Metal Value Date:
                </td>
                <td class="pl-2">
                  {{ metalValueDate | formatDate }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col
          cols="6"
        >
          <tbody>
            <tr>
              <td class="font-weight-bold">
                Customer Ref:
              </td>
              <td class="pl-2">
                {{ customerReference }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                Date Received:
              </td>
              <td class="pl-2">
                {{ dateReceived | formatDate }}
              </td>
            </tr>
          </tbody>
        </v-col>
      </v-row>
      <div class="text-h5">
        Weights & Units
      </div>
      <hr>
      <v-row>
        <v-col cols="2">
          <v-simple-table>
            <thead>
              <tr>
                <th>Total Units</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ totalUnits }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col cols="10">
          <v-simple-table>
            <thead>
              <tr>
                <th>Gross Received</th>
                <th>Tare / Scrap</th>
                <th>Net Wet Received</th>
                <th>Percent Moisture</th>
                <th>Net Dry</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ grossReceived }} lbs</td>
                <td>{{ scrapWeight }} lbs</td>
                <td>{{ netWetReceived }} lbs</td>
                <td>{{ percentMoisture }}%</td>
                <td>{{ netDryWeight }} lbs</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <div class="text-h5">
        Assays
      </div>
      <hr>
      <v-row>
        <v-col
          cols="2"
          offset-md="2"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th />
              </tr>
            </thead>
            <tbody class="text-right">
              <tr>
                <td>Total</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col
          cols="8"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th>PT PPM</th>
                <th>PD PPM</th>
                <th>RH PPM</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ ptPPM }}</td>
                <td>{{ pdPPM }}</td>
                <td>{{ rhPPM }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <div class="text-h5">
        Contract Ounces
      </div>
      <hr>
      <v-row>
        <v-col
          cols="2"
          offset-md="2"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th />
              </tr>
            </thead>
            <tbody class="text-right">
              <tr>
                <td>Total</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col
          cols="8"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th>PT TROY OUNCES</th>
                <th>PD TROY OUNCES</th>
                <th>RH TROY OUNCES</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ ptToz }}</td>
                <td>{{ pdToz }}</td>
                <td>{{ rhToz }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <div class="text-h5">
        Returnable Ounces
      </div>
      <hr>
      <v-row>
        <v-col
          cols="2"
          offset-md="2"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th />
              </tr>
            </thead>
            <tbody class="text-right">
              <tr>
                <td>Return %</td>
              </tr>
              <tr>
                <td>Total</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col
          cols="8"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th>PT TROY OUNCES</th>
                <th>PD TROY OUNCES</th>
                <th>RH TROY OUNCES</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ ptReturnRate }}%</td>
                <td>{{ pdReturnRate }}%</td>
                <td>{{ rhReturnRate }}%</td>
              </tr>
              <tr>
                <td>{{ getPtTotal }}</td>
                <td>{{ getPdTotal }}</td>
                <td>{{ getRhTotal }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <div class="text-h5">
        Summary of Charges
      </div>
      <hr>
      <v-row>
        <v-col
          cols="10"
          offset-md="2"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th>Lab Fee</th>
                <th>Treatment Charge</th>
                <th>Refining Charge</th>
                <th>Shredding Fee</th>
                <th>Small Lot Fee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ labFee }}</td>
                <td>{{ treatmentCharge }} lbs</td>
                <td>{{ refiningFee }} lbs</td>
                <td>{{ shreddingFee }} lbs</td>
                <td>{{ smallLotFee }} lbs</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </unauthorized>
</template>

<script>
import BrokeredSettlementSkeleton from '@/views/Lots/components/Brokered/components/BrokeredSettlementSkeleton.vue'
import config from '@/../izzi_config'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ClientProformaPrint',
  components: {
    BrokeredSettlementSkeleton
  },
  data () {
    return {
      config,
      error: null,
      breadcrumbs: [
        ['lot list', { name: 'lot-list' }],
        [`Lot #${this.$route.params.id}`, { name: 'lot' }],
        [`Shipment Results`, { name: 'shipment-result' }],
        ['Print', { name: 'shipment-result-print' }]
      ],
      loading: false
    }
  },
  computed: {
    ...mapState('shipmentResultStore', [
      'hasProforma',
      'historyVersion',
      'history',
      'yardChoices',
      'lotName',
      'lotId',
      'yardId',
      'customerReference',
      'dateReceived',
      'metalValueDate',
      'totalUnits',
      'grossReceived',
      'scrapWeight',
      'percentMoisture',
      'netWetReceived',
      'netDryWeight',
      'ptPPM',
      'ptToz',
      'ptReturnRate',
      'pdPPM',
      'pdToz',
      'pdReturnRate',
      'rhPPM',
      'rhToz',
      'rhReturnRate',
      'labFee',
      'treatmentCharge',
      'refiningFee',
      'shreddingFee',
      'lotCharge',
      'smallLotFee'
    ]),
    ...mapGetters('shipmentResultStore', [
      'getPtTotal',
      'getPdTotal',
      'getRhTotal',
      'getTotalOfCharges'
    ]),
    yardName () {
      const yardId = this.$store.getters['clientProformaStore/getYardId']
      const yards = this.$store.getters['clientProformaStore/getYardChoices']
      const yard = yards.find(y => y.id === yardId)
      return yard?.name
    }
  },
  methods: {
    initialize () {
      return this.$store.dispatch('shipmentResultStore/fetchShipmentResults')
    }
  },
  /*
   * Fetch the history, if no history then fetch the latest lot seed data
   */
  async mounted () {
    this.loading = true
    this.$store.commit('shipmentResultStore/clearState')
    this.$store.commit('shipmentResultStore/setLotId', this.$route.params.id)
    await this.initialize()
    if (this.history.length) {
      this.$store.commit('shipmentResultStore/setContextFromHistory', this.history[0])
    } else {
      // if there is no history, then seed data
      this.error = 'Unable to load a Shipment Result'
    }
    this.loading = false
  }
}
</script>
