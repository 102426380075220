var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('app-loading-indicator'):_c('v-container',[_c('base-title',{attrs:{"title":"Create Intake"}}),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('help-dialog',{attrs:{"headline-text":"About the intake process"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"small":""},on:{"click":on}},[_vm._v(" About ")])]}}])},[_c('p',[_vm._v(" Before catalytic converters can be cut they must first be sorted and counted in the process of creating an "),_c('strong',[_vm._v("Intake")]),_vm._v(". ")]),_c('p',[_vm._v(" When purchase creation is completed all related InventoryBoxes will be automatically marked as CUT (regardless of location). ")]),_c('p',[_vm._v(" A Intake email detailing the check in counts and intake counts will be sent to "),_c('em',[_vm._v("new_intake")]),_vm._v(" recipients. ")])])],1),_c('v-sheet',{staticClass:"px-4"},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('search-autocomplete',{attrs:{"label":"Purchase Search","hint":"type to start searching","placeholder":"Start typing to Search","api-path":"/api/purchases/","multiple":"","params":{ status: 'RE', check_ins__status: 'CH' },"format-entry":_vm.formatPurchase},model:{value:(_vm.purchases),callback:function ($$v) {_vm.purchases=$$v},expression:"purchases"}})]}}],null,true)})],1)],1),_c('v-list',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.purchases.length > 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('ValidationProvider',{attrs:{"rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-n4",attrs:{"label":"Reference","counter":"30","error-messages":errors},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Notes","type":"string","counter":"256","rows":"4","error-messages":errors},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(" You must select a purchase to continue ")])]),_vm._l((_vm.allLines),function(line,i){return _c('v-list-item',{key:i,attrs:{"dense":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 3 : (_vm.$vuetify.breakpoint.sm ? 2 : 1)}},[(line.converterCategory)?_c('v-chip',{attrs:{"color":_vm.getColorByConverterCategory(line.converterCategory)}},[_vm._v(" "+_vm._s(line.converterCategory)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 4 : 5}},[_c('div',[_vm._v(" "+_vm._s(line.name)+" ")])]),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.purchases.length > 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-text-field',{attrs:{"disabled":_vm.purchases.length === 0,"type":"number","suffix":line.units.toLowerCase(),"error-messages":errors},model:{value:(line.value),callback:function ($$v) {_vm.$set(line, "value", $$v)},expression:"line.value"}})],1)]}}],null,true)},[_c('span',[_vm._v(" You must select a purchase to continue ")])])]}}],null,true)})],1)],1)],1)],1)})],2),_c('v-dialog',{model:{value:(_vm.allocationDialog),callback:function ($$v) {_vm.allocationDialog=$$v},expression:"allocationDialog"}},[_c('allocation-select',{on:{"selected":_vm.setAllocations}})],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":invalid || _vm.addingIntake || _vm.purchases.length === 0 || _vm.loadingMoreLines,"loading":_vm.addingIntake,"color":"primary"},on:{"click":function($event){_vm.allocationDialog = true}}},[_vm._v(" Select Allocations ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.allocations || invalid || _vm.addingIntake || _vm.purchases.length === 0 || _vm.loadingMoreLines,"loading":_vm.addingIntake,"color":"primary"},on:{"click":_vm.addIntake}},[_vm._v(" Save Intake ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }