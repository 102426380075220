<template>
  <unauthorized
    :authorized="$store.getters.can('create-purchases')"
    message="Not Authorized to Create Purchases"
  >
    <v-container>
      <ValidationObserver
        v-slot="{ invalid }"
        ref="validationObserver"
      >
        <v-form @submit.prevent="submitCreate">
          <v-card>
            <v-card-title>
              Purchase
            </v-card-title>
            <v-card-subtitle>
              The basic information
            </v-card-subtitle>
            <v-card-text>
              <v-sheet max-width="600">
                <date-input
                  v-model="estPurchaseDate"
                  label="Estimated Purchase Date *"
                />
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="division"
                    label="Division*"
                    :items="divisions.filter(d => !d.is_inactive)"
                    item-text="name"
                    return-object
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="projectedPieces"
                    label="Projected Catalytic Pieces*"
                    type="number"
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <paginated-autocomplete
                  label="Select PPM Calculator"
                  v-model="ppmCalculation"
                  item-text="name"
                  item-value="id"
                  base-url="/api/ppm_calculations/"
                />
                <ValidationProvider
                  rules="max:64"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Customer Note"
                    v-model="customerRef"
                    counter="64"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-sheet>
            </v-card-text>
            <v-card-subtitle>
              Information for the hedge management team
            </v-card-subtitle>
            <v-card-text>
              <v-sheet
                max-width="600"
              >
                <v-radio-group
                  v-model="hedgeRequested"
                  label="Do you need a Hedge?"
                >
                  <v-radio
                    label="No"
                    :value="false"
                  />
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                </v-radio-group>
                <v-textarea
                  v-model="hedgeMemo"
                  :counter="true"
                  :rows="4"
                  auto-grow
                  maxlength="280"
                  label="Hedge Memo"
                />
              </v-sheet>
            </v-card-text>
          </v-card>
          <v-divider class="my-4" />
          <v-card>
            <v-card-title>
              Check In
            </v-card-title>
            <v-card-subtitle>
              Check In Information
            </v-card-subtitle>
            <v-card-text>
              <v-sheet
                max-width="600"
              >
                <v-radio-group
                  v-model="straightThrough"
                  label="Create a CheckIn?"
                >
                  <v-radio
                    label="No"
                    :value="false"
                  />
                  <v-radio
                    label="Yes"
                    :value="true"
                  />
                </v-radio-group>
                <div v-if="straightThrough">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-radio-group
                      v-model="checkInStatus"
                      label="Initial CheckIn Status"
                      :error-messages="errors"
                    >
                      <v-radio
                        v-for="status in statuses"
                        :key="status.value"
                        :label="status.text"
                        :value="status.value"
                      />
                    </v-radio-group>
                  </ValidationProvider>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-radio-group
                      v-model="currency"
                      label="Currency"
                      :error-messages="errors"
                    >
                      <v-radio
                        v-for="cur in configCurrencies"
                        :key="cur.value"
                        :label="cur.text"
                        :value="cur.value"
                      />
                    </v-radio-group>
                  </ValidationProvider>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <paginated-autocomplete
                      v-model="yard"
                      label="Select Yard*"
                      :item-text="x => `${x.name} (${x.city || '--'}, ${x.state || '--'})`"
                      item-value="url"
                      base-url="/api/yards/"
                      :search-params="{ is_active: true }"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <v-radio-group
                    v-model="requiresTransit"
                    label="Is Transit Required?"
                  >
                    <v-radio
                      label="No"
                      :value="false"
                    />
                    <v-radio
                      label="Yes"
                      :value="true"
                    />
                  </v-radio-group>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="To be delivered to*"
                      v-model="requiresTransitTo"
                      required
                      item-text="name"
                      return-object
                      :items="divisions.filter(d => !d.is_inactive)"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <v-radio-group
                    v-model="sendCheckInEmailsImmediate"
                    label="Send Check In Created Emails?"
                  >
                    <v-radio
                      label="No"
                      :value="false"
                    />
                    <v-radio
                      label="Yes"
                      :value="true"
                    />
                  </v-radio-group>
                  <v-textarea
                    v-model="checkInNotes"
                    :counter="true"
                    :rows="4"
                    auto-grow
                    maxlength="280"
                    label="Notes"
                  />
                </div>
              </v-sheet>
            </v-card-text>
          </v-card>
          <text-fab
            :loading="creatingPurchase"
            :disabled="invalid || creatingPurchase"
            bottom
            right
            fixed
            icon-text="mdi-plus"
            color="primary"
            type="submit"
          >
            <span v-if="straightThrough">Create Purchase/CheckIn</span>
            <span v-else>Create Purchase</span>
          </text-fab>
        </v-form>
      </ValidationObserver>
    </v-container>
  </unauthorized>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'

export default {
  name: 'CreatePurchase',
  data: () => ({
    loading: true,
    division: null,
    ppmCalculation: null,
    customerRef: '',
    projectedPieces: 0,
    dateDialog: false,
    estPurchaseDate: new Date().toISOString().substr(0, 10),
    futureDates: new Date().toISOString().substr(0, 10),
    hedgeRequested: false,
    hedgeMemo: '',
    currentLocation: null,
    creatingPurchase: false,
    straightThrough: true,
    yard: null,
    checkInStatus: null,
    checkInNotes: '',
    currency: '',
    requiresTransitTo: null,
    requiresTransit: false,
    sendCheckInEmailsImmediate: true
  }),
  watch: {
    division (value) {
      // mirrored automatically
      this.requiresTransitTo = value
    }
  },
  methods: {
    ...mapMutations({
      setPurchase: 'purchaseStore/setPurchase',
      setCheckIn: 'checkIn/setCheckIn'
    }),
    ...mapActions({
      fetchDivisions: 'divisionStore/fetchDivisions',
      createPurchaseAction: 'purchaseStore/createPurchase',
      createCheckInAction: 'checkIn/createCheckIn'
    }),
    /**
     * Create a new purchase using the form filled out by the user
     */
    async createPurchase () {
      try {
        const purchaseBody = {
          division_id: this.division.id,
          customer_ref: this.customerRef,
          projected_pieces: this.projectedPieces,
          est_purchase_date: this.estPurchaseDate,
          hedge_is_requested: this.hedgeRequested,
          hedge_request_memo: this.hedgeMemo
        }
        if (this.ppmCalculation) purchaseBody.ppm_calculation_id = this.ppmCalculation
        await this.createPurchaseAction(purchaseBody)
      } catch (e) {
        return null
      }
    },
    createCheckIn () {
      const payload = {
        status: this.checkInStatus,
        purchase: this.purchase.url,
        yard: this.yard,
        preliminary_pieces: this.projectedPieces,
        notes: this.checkInNotes,
        currency: this.currency,
        requires_transit: this.requiresTransit
      }
      if (this.requiresTransitTo && this.requiresTransitTo?.logistics_location?.url) {
        payload.to_location = this.requiresTransitTo.logistics_location.url
      }
      return this.createCheckInAction(payload)
    },
    async submitCreate () {
      this.creatingPurchase = true
      await this.createPurchase()
      if (this.purchase && this.straightThrough) {
        await this.createCheckIn()
      }

      // Route user or display error
      if (this.checkIn) {
        // Send emails
        if (this.sendCheckInEmailsImmediate) {
          await axios.post(`/api/check_ins/${this.checkIn.id}/send_emails/`)
          this.$store.commit('setSnackbarSuccess', 'CheckIn Emails Sent')
        }
        this.$router.push({ name: 'edit-checkin', params: { id: this.checkIn.id } })
      } else if (this.purchase) {
        this.$router.push({ name: 'purchase', params: { id: this.purchase.id } })
      } else {
        this.$store.commit('setSnackbarSuccess', 'Failed to create the Purchase')
      }
      this.creatingPurchase = false
    }
  },
  computed: {
    ...mapGetters({
      configCurrencies: 'configCurrencies',
      configCurrenciesDefault: 'configCurrenciesDefault',
      divisions: 'divisionStore/divisions',
      purchase: 'purchaseStore/purchase',
      checkIn: 'checkIn/checkIn'
    }),
    statuses () {
      let options = []
      if (this.$store.getters.can('create-preliminary-check-in')) options.push({ value: 'PR', text: 'Preliminary' })
      if (this.$store.getters.can('create-open-check-in')) options.push({ value: 'OP', text: 'Open' })
      return options
    }
  },
  mounted () {
    this.fetchDivisions()
    this.setPurchase(null)
    this.setCheckIn(null)
    if (this.configCurrenciesDefault) {
      this.currency = this.configCurrenciesDefault.value
    }
    if (this.statuses.length) {
      this.checkInStatus = this.statuses[0].value
    }
  }
}
</script>
