<template>
  <unauthorized
    :authorized="$store.getters.can('view-shipment-result')"
    message="You are not authorized to view Shipment Results"
  >
    <brokered-settlement-skeleton
      v-if="loading"
      title="Shipment Results"
    />
    <ValidationObserver
      v-else
      v-slot="{ invalid, reset }"
    >
      <v-form
        :readonly="isLocked"
        :value="invalid"
        class="mb-16"
      >
        <v-container>
          <v-alert
            v-if="!hasProforma"
            type="warning"
            outlined
          >
            Complete a Client Proforma before working on Shipment Results.
          </v-alert>
          <url-breadcrumbs
            v-if="breadcrumbs"
            :items="breadcrumbs"
          />
          <h1>
            Shipment Results
          </h1>
          <div class="caption">
            Provides a detailed client shipment report.
          </div>
          <v-toolbar
            flat
            dense
            color="transparent"
          >
            <v-btn
              :color="isLocked ? 'error' : ''"
              small
              rounded
              @click="isLocked = !isLocked"
            >
              {{ isLocked ? 'Locked' : 'unlocked' }}
              <v-icon
                small
                class="ml-2"
              >
                {{ isLocked ? 'mdi-lock-outline' : 'mdi-lock-open-outline' }}
              </v-icon>
            </v-btn>
            <v-spacer />
            <info-tooltip>
              <template v-slot:wrap-me>
                <v-btn
                  :disabled="isLocked"
                  text
                  @click="refreshData"
                >
                  Refresh Data
                </v-btn>
              </template>
              Replace values with the latest lot data.
            </info-tooltip>
            <v-btn
              :disabled="!history.length"
              text
              class="ml-2"
              target="_blank"
              :to="{ name: 'shipment-result-print', params: { id: $route.params.id } }"
            >
              Print
            </v-btn>
          </v-toolbar>
          <v-select
            :value="historyVersion"
            label="Revision History"
            :items="history"
            :item-text="historyLabelFormat"
            item-value="id"
            @change="setVersionFromHistoryById"
          />
          <div>
            <h2>
              Customer
            </h2>
            <v-sheet class="pa-4">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="lotName"
                    readonly
                    class="dense-filled"
                    label="Lot Name"
                    validation-rules="required|max:400"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    immediate
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="Yard"
                      :value="yardId"
                      :items="yardChoices"
                      :item-text="x => `${x.id} - ${x.name} ${x.address || ''}, ${x.city || ''} ${x.state || ''}`"
                      item-value="id"
                      :error-messages="errors"
                      @input="$store.commit('shipmentResultStore/setYardId', $event)"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="customerReference"
                    label="Customer Reference"
                    validation-rules="required|max:400"
                    @input="$store.commit('shipmentResultStore/setCustomerReference', $event)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    :value="dateReceived"
                    label="Date Recieved"
                    :show-date-picker="!isLocked"
                    @input="$store.commit('shipmentResultStore/setDateReceived', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    :value="metalValueDate"
                    label="Metal Value Date"
                    :show-date-picker="!isLocked"
                    @input="$store.commit('shipmentResultStore/setMetalValueDate', $event)"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <div class="py-4" />
            <h2>
              Weights and Units
            </h2>
            <v-sheet class="pa-4">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    validation-rules="required|max_value:2147483647|min_value:0|decimal:0"
                    :value="totalUnits"
                    label="Total Units"
                    type="number"
                    @input="$store.commit('shipmentResultStore/setTotalUnits', $event)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!--Just a hack to push the other field down-->
                  <v-text-field
                    style="opacity: 0;"
                  />
                  <rules-text
                    :value="grossReceived"
                    validation-rules="required|max_value:999999999|min_value:0|decimal:3"
                    label="Gross Received (lbs)"
                    type="number"
                    @input="$store.commit('shipmentResultStore/setGrossReceived', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="scrapWeight"
                    validation-rules="required|max_value:999999999|min_value:0|decimal:3"
                    label="Tare / Scrap"
                    type="number"
                    @input="$store.commit('shipmentResultStore/setScrapWeight', $event)"
                  />
                  <rules-text
                    :value="netWetReceived"
                    validation-rules="required|max_value:999999999|min_value:0|decimal:3"
                    label="Net Wet Received (lbs)"
                    type="number"
                    @input="$store.commit('shipmentResultStore/setNetWetReceived', $event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="percentMoisture"
                    validation-rules="required|max_value:999999999|min_value:0|decimal:3"
                    label="Percent Moisture"
                    type="number"
                    @input="$store.commit('shipmentResultStore/setPercentMoisture', $event)"
                  />
                  <rules-text
                    :value="netDryWeight"
                    validation-rules="required|max_value:999999999|min_value:0|decimal:3"
                    label="Net Dry (lbs)"
                    type="number"
                    @input="$store.commit('shipmentResultStore/setNetDryWeight', $event)"
                  />
                </v-col>
              </v-row>
            </v-sheet>
          </div>
          <h2>
            Assays
          </h2>
          <v-sheet class="pa-4">
            <v-checkbox
              v-model="showAssays"
              label="Show Assays"
            />
            <v-row>
              <v-col>
                <rules-text
                  :value="ptPPM"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:3"
                  label="PT PPM"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setPtPPM', $event)"
                />
              </v-col>
              <v-col>
                <rules-text
                  :value="pdPPM"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:3"
                  label="PD PPM"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setPdPPM', $event)"
                />
              </v-col>
              <v-col>
                <rules-text
                  :value="rhPPM"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:3"
                  label="RH PPM"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setRhPPM', $event)"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Contract Ounces
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col>
                <rules-text
                  :value="ptToz"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:3"
                  label="PT t oz"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setPtToz', $event)"
                />
              </v-col>
              <v-col>
                <rules-text
                  :value="pdToz"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:3"
                  label="PD t oz"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setPdToz', $event)"
                />
              </v-col>
              <v-col>
                <rules-text
                  :value="rhToz"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:3"
                  label="RH t oz"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setRhToz', $event)"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Returnable Ounces
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col>
                <rules-text
                  :value="ptReturnRate"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:2"
                  hint="Use whole numbers as percent"
                  label="PT Return Rate %"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setPtReturnRate', $event)"
                />
                <rules-text
                  :value="getPtTotal"
                  readonly
                  class="dense-filled"
                  validation-rules="required"
                  label="PT Total"
                  type="number"
                />
              </v-col>
              <v-col>
                <rules-text
                  :value="pdReturnRate"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:2"
                  hint="Use whole numbers as percent"
                  label="PD Return Rate %"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setPdReturnRate', $event)"
                />
                <rules-text
                  :value="getPdTotal"
                  readonly
                  class="dense-filled"
                  validation-rules="required"
                  label="PD Total"
                  type="number"
                />
              </v-col>
              <v-col>
                <rules-text
                  :value="rhReturnRate"
                  validation-rules="required|max_value:2147483647|min_value:0|decimal:2"
                  hint="Use whole numbers as percent"
                  label="RH Return Rate %"
                  type="number"
                  @input="$store.commit('shipmentResultStore/setRhReturnRate', $event)"
                />
                <rules-text
                  :value="getRhTotal"
                  readonly
                  class="dense-filled"
                  validation-rules="required"
                  label="RH Total"
                  type="number"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Summary of Charges
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  :value="labFee"
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  type="number"
                  label="Lab Fee"
                  prepend-icon="mdi-currency-usd"
                  @input="$store.commit('shipmentResultStore/setLabFee', $event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  :value="treatmentCharge"
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  type="number"
                  label="Treatment Charge"
                  prepend-icon="mdi-currency-usd"
                  @input="$store.commit('shipmentResultStore/setTreatmentCharge', $event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  :value="refiningFee"
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  type="number"
                  label="Refining Fee"
                  prepend-icon="mdi-currency-usd"
                  @input="$store.commit('shipmentResultStore/setRefiningFee', $event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  :value="shreddingFee"
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  type="number"
                  label="Shredding Fee"
                  prepend-icon="mdi-currency-usd"
                  @input="$store.commit('shipmentResultStore/setShreddingFee', $event)"
                />
              </v-col>
              <!--              <v-col-->
              <!--                cols="12"-->
              <!--                md="3"-->
              <!--              >-->
              <!--                <rules-text-->
              <!--                  :value="lotCharge"-->
              <!--                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"-->
              <!--                  type="number"-->
              <!--                  label="Lot Charge"-->
              <!--                  prepend-icon="mdi-currency-usd"-->
              <!--                  @input="$store.commit('shipmentResultStore/setLotCharge', $event)"-->
              <!--                />-->
              <!--              </v-col>-->
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  :value="smallLotFee"
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  type="number"
                  label="Small Lot Fee"
                  prepend-icon="mdi-currency-usd"
                  @input="$store.commit('shipmentResultStore/setSmallLotFee', $event)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
                offset-md="9"
              >
                <rules-text
                  :value="getTotalOfCharges"
                  readonly
                  class="dense-filled"
                  type="number"
                  label="Total of Charges"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-container>
        <text-fab
          v-if="!isLocked"
          bottom
          right
          fixed
          icon-text="mdi-content-save-outline"
          :color="isModified ? 'primary': ''"
          @click="save"
        >
          Save Changes
        </text-fab>
      </v-form>
    </ValidationObserver>
  </unauthorized>
</template>

<script>

import BrokeredSettlementSkeleton from '@/views/Lots/components/Brokered/components/BrokeredSettlementSkeleton.vue'
import RulesText from '@/components/ValidationFields/RulesText.vue'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ShipmentResult',
  components: {
    RulesDate,
    RulesText,
    BrokeredSettlementSkeleton
  },
  data () {
    return {
      isModified: false,
      breadcrumbs: [
        ['lot list', { name: 'lot-list' }],
        [`Lot #${this.$route.params.id}`, { name: 'lot' }],
        [`Shipment Results`, { name: 'shipment-result' }]
      ],
      loading: false,
      readonly: true
    }
  },
  watch: {
    snapshot () {
      this.isModified = true
    }
  },
  computed: {
    snapshot () {
      // To enable the isModified watcher
      return this.$store.getters['shipmentResultStore/getStateSnapshot']
    },
    showAssays: {
      get () {
        return this.$store.state['shipmentResultStore']['showAssays']
      },
      set (value) {
        console.log(value)
        this.$store.commit('shipmentResultStore/setShowAssays', value)
      }
    },
    ...mapState('shipmentResultStore', [
      'hasProforma',
      'historyVersion',
      'history',
      'yardChoices',
      'lotName',
      'lotId',
      'yardId',
      'customerReference',
      'dateReceived',
      'metalValueDate',
      'totalUnits',
      'grossReceived',
      'scrapWeight',
      'percentMoisture',
      'netWetReceived',
      'netDryWeight',
      'ptPPM',
      'ptToz',
      'ptReturnRate',
      'pdPPM',
      'pdToz',
      'pdReturnRate',
      'rhPPM',
      'rhToz',
      'rhReturnRate',
      'labFee',
      'treatmentCharge',
      'refiningFee',
      'shreddingFee',
      'lotCharge',
      'smallLotFee'
    ]),
    ...mapGetters('shipmentResultStore', [
      'getPtTotal',
      'getPdTotal',
      'getRhTotal',
      'getTotalOfCharges'
    ]),
    isLocked: {
      get () {
        return this.$store.getters['shipmentResultStore/getIsLocked']
      },
      set (value) {
        this.$store.commit('shipmentResultStore/setIsLocked', value)
      }
    },
    ...mapGetters('shipmentResultStore', {
    })
  },
  methods: {
    historyLabelFormat (history) {
      let text = `${history.id}. ${moment(history.created_at).format('MM/DD/YYYY HH:mm:ss')} ${history.fx_currency}`
      if (this.history.length > 0 && history.id === this.history[0].id) text += ' (Latest)'
      return text
    },
    save () {
      this.$store.dispatch('shipmentResultStore/saveShipmentResult')
      this.isModified = false
    },
    setVersionFromHistoryById (id) {
      const history = this.history.find(h => h.id === id)
      if (history) {
        this.$store.commit('shipmentResultStore/setContextFromHistory', history)
        return true
      }
      return false
    },
    refreshData () {
      return this.$store.dispatch('shipmentResultStore/refreshDataFromLotData')
    },
    initialize () {
      return this.$store.dispatch('shipmentResultStore/fetchShipmentResults')
    }
  },
  beforeRouteLeave (to, from, next) {
    if (window.confirm('Leave without saving?')) next()
  },
  /*
   * Fetch the history, if no history then fetch the latest lot seed data
   */
  async mounted () {
    this.loading = true
    this.$store.commit('shipmentResultStore/clearState') // Makes sure the store is fresh
    this.$store.commit('shipmentResultStore/setLotId', this.$route.params.id)
    await this.initialize()
    if (this.history.length) {
      this.$store.commit('shipmentResultStore/setContextFromHistory', this.history[0])
    } else {
      // if there is no history, then seed data
      await this.refreshData()
      this.isLocked = false
    }
    this.loading = false
  }
}
</script>
