<template>
  <unauthorized
    :authorized="$store.getters.can('view-advanced-calculator')"
    message="You are not authorized to view lots"
  >
    <brokered-settlement-skeleton
      v-if="loading"
      title="Advance Calculator"
    />
    <ValidationObserver
      v-else
      v-slot="{ invalid, reset }"
    >
      <v-form
        :value="invalid"
        class="mb-16"
      >
        <v-container>
          <url-breadcrumbs
            v-if="breadcrumbs"
            :items="breadcrumbs"
          />
          <h1>Advance Calculator</h1>
          <div class="caption">
            Calculates the estimated value of a lot and projects potential returns. Used to determine appropriate advanced payments to clients based on the lot’s expected performance.
          </div>
          <v-toolbar
            flat
            dense
            color="transparent"
          >
            <v-spacer />
            <info-tooltip>
              <template v-slot:wrap-me>
                <v-btn
                  text
                  @click="refreshData"
                >
                  Refresh Data
                </v-btn>
              </template>
              Replace values with the latest lot data.
            </info-tooltip>
            <v-btn
              :disabled="!history.length"
              text
              class="ml-2"
              target="_blank"
              :to="{ name: 'advance-calculator-print', params: { id: $route.params.id } }"
            >
              Print
            </v-btn>
          </v-toolbar>
          <v-select
            :value="historyVersion"
            label="Revision History"
            :items="history"
            :item-text="historyLabelFormat"
            item-value="id"
            @change="setVersionFromHistoryById"
          />
          <div>
            <h2>
              Properties
            </h2>
            <v-sheet class="pa-4">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="lotName"
                    readonly
                    class="dense-filled"
                    label="Lot Name"
                    validation-rules="required|max:400"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    immediate
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="Yard"
                      v-model="yardId"
                      :items="$store.getters['advancedCalculatorStore/getYardChoices']"
                      :item-text="x => `${x.id} - ${x.name} ${x.address || ''}, ${x.city || ''} ${x.state || ''}`"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    label="Est. Ship Date"
                    v-model="shipDate"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    validation-rules="required|max_value:2147483647|min_value:0|decimal:0"
                    v-model="convertersProcessed"
                    label="Converters Processed"
                    type="number"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    validation-rules="required|max_value:999999999|min_value:0|decimal:3"
                    v-model="mixedCatalystWeight"
                    label="Mixed Catalyst Weight/Received Lbs"
                    type="number"
                  />
                  <div
                    class="text-center"
                  >
                    <v-btn
                      :disabled="mixedCatalystWeight === estimatedWeightFromPieces"
                      outlined
                      small
                      @click="mixedCatalystWeight = estimatedWeightFromPieces"
                    >
                      Use {{ pcToWeightConversionRate }} pc/lb of {{ estimatedWeightFromPieces }} lbs
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    label="Client Value Date"
                    v-model="clientValueDate"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <div class="py-4" />
            <h2>
              Outright Units
            </h2>
            If the units are already paid, the are not included in the deposit calculation
            <v-sheet class="pa-4 mb-6">
              <v-simple-table>
                <thead>
                  <tr>
                    <th
                      v-for="h in ['Type', 'Quantity', 'Unit Price', 'Total Price', 'Mark Paid']"
                      :key="h"
                      :style="'min-width:' + (['Type', 'Mark Paid'].includes(h)) ? 50 : 100 + 'px;'"
                      v-text="h"
                    />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="outright in $store.getters['advancedCalculatorStore/getPurchasedOutright']"
                    :key="outright.materialType"
                  >
                    <td>
                      <strong>{{ outright.materialType }}</strong>
                    </td>
                    <td>
                      <rules-text
                        validation-rules="required|max_value:2147483647|min_value:0|decimal:0"
                        :value="outright.quantity"
                        type="number"
                        @input="updateOutrightValue(outright.materialType, 'quantity', $event)"
                      />
                    </td>
                    <td>
                      <rules-text
                        validation-rules="required|max_value:9999999999|min_value:0|decimal:2"
                        :value="outright.price"
                        type="number"
                        prepend-icon="mdi-currency-usd"
                        @input="updateOutrightValue(outright.materialType, 'price', $event)"
                      />
                    </td>
                    <td class="pt-2">
                      <rules-text
                        dense
                        class="dense-filled"
                        readonly
                        validation-rules="required|max_value:9999999999|min_value:0|decimal:2"
                        :value="outright.totalPrice"
                        type="number"
                        prepend-icon="mdi-currency-usd"
                      />
                    </td>
                    <td class="pb-4 pl-6">
                      <v-simple-checkbox
                        :value="outright.paid"
                        color="primary"
                        @input="updateOutrightValue(outright.materialType, 'paid', $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-row>
                <v-col
                  cols="12"
                  offset-md="9"
                  md="3"
                >
                  <rules-text
                    class="dense-filled"
                    :value="grossOutrightUnpaidValue"
                    label="Gross Unpaid Value"
                    prepend-icon="mdi-currency-usd"
                    hint="Sum of Unpaid Outright"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <div class="py-4" />
            <h2>
              Metal Allocations
            </h2>
            <v-sheet class="pa-4">
              <span class="h2">Expected Return Values for Lot</span>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="containedPt"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Contained Pt"
                  />
                  <div class="text-center text-sm-body-2 mb-4">
                    <a @click="containedPt = estimatedPtCtoz">
                      Use PPM Calculator estimate {{ estimatedPtCtoz }}
                    </a>
                  </div>
                  <rules-text
                    v-model="brokeredRatePt"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Brokered Rate Pt"
                  />
                  <rules-text
                    v-model="availablePt"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Pt"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedPt / availablePt * 100"
                    color="#0068b9"
                    rounded
                  >
                    <span class="font-weight-medium white--text">
                      {{ allocatedPt }} / {{ availablePt }} rtoz
                    </span>
                  </v-progress-linear>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="containedPd"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Contained Pd"
                  />
                  <div class="text-center text-sm-body-2 mb-4">
                    <a @click="containedPd = estimatedPdCtoz">
                      Use PPM Calculator estimate {{ estimatedPdCtoz }}
                    </a>
                  </div>
                  <rules-text
                    v-model="brokeredRatePd"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Brokered Rate Pd"
                  />
                  <rules-text
                    v-model="availablePd"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Pd"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedPd / availablePd * 100"
                    color="#d00e00"
                    rounded
                  >
                    <span class="font-weight-medium white--text">
                      {{ allocatedPd }} / {{ availablePd }} rtoz
                    </span>
                  </v-progress-linear>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="containedRh"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Contained Rh"
                  />
                  <div class="text-center text-sm-body-2 mb-4">
                    <a @click="containedRh = estimatedRhCtoz">
                      Use PPM Calculator estimate {{ estimatedRhCtoz }}
                    </a>
                  </div>
                  <rules-text
                    v-model="brokeredRateRh"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Brokered Rate Rh"
                  />
                  <rules-text
                    v-model="availableRh"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Rh"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedRh / availableRh * 100"
                    color="#006104"
                    rounded
                  >
                    <span class="font-weight-medium white--text">
                      {{ allocatedRh }} / {{ availableRh }} rtoz
                    </span>
                  </v-progress-linear>
                </v-col>
              </v-row>
              <div
                v-for="allocation in allocations"
                :key="allocation.hedgeId"
              >
                <div class="d-flex justify-space-between align-center">
                  <div class="text-h6">
                    Hedge #{{ allocation.hedgeId }}
                  </div>
                  <v-btn
                    outlined
                    x-small
                    @click="removeAllocation(allocation)"
                  >
                    Remove
                  </v-btn>
                </div>
                <v-row>
                  <v-col>
                    <edit-metal
                      alternate-style
                      :hedge="allocation"
                      @setHedge="updateAllocations(allocation.hedgeId, $event)"
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="text-right">
                <v-select
                  v-model="selectedHedge"
                  :items="unallocatedHedges"
                  :item-text="x => `Hedge #${x.id} ${x.ptAvailableToz}@${x.ptRate} / ${x.pdAvailableToz}@${x.pdRate} / ${x.rhAvailableToz}@${x.rhRate}`"
                  return-object
                  label="Selected Hedge to Add"
                  hint="Add any Designated Hedges to the Allocations"
                />
                <v-btn
                  :disabled="!selectedHedge"
                  small
                  @click="addSelectedHedge"
                >
                  Add Hedge
                </v-btn>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="allocatedReturnablePt"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Pt rtoz"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="allocatedReturnablePd"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Pd rtoz"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    immediate
                    :value="allocatedReturnableRh"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Rh rtoz"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  offset-md="9"
                  md="3"
                >
                  <rules-text
                    class="dense-filled"
                    :value="grossMetalValue"
                    label="Gross Metal Value"
                    prepend-icon="mdi-currency-usd"
                    hint="Sum Hedge Values"
                  />
                </v-col>
              </v-row>
            </v-sheet>
          </div>
          <div class="py-4" />
          <h2>
            Fees
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="refiningFee"
                  type="number"
                  label="Refining Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a @click="refiningFee = termsRefiningFeeTotal">
                    Use fee from terms {{ termsRefiningFeeTotal }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="processingFee"
                  type="number"
                  label="Processing Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a @click="processingFee = termsTreatmentCharge">
                    Use charges from terms {{ termsTreatmentCharge }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="handlingFee"
                  type="number"
                  label="Handling Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a @click="handlingFee = termsHandlingFee">
                    Use fee from terms {{ termsHandlingFee }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="freightCharge"
                  type="number"
                  label="Freight Charge"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="assayFee"
                  type="number"
                  label="Assay Fee"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="shreddingFee"
                  type="number"
                  label="Shredding Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a @click="shreddingFee = termShreddingFee">
                    Use fee from terms {{ termShreddingFee }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="netOtherFees"
                  type="number"
                  label="Net Other Fees"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-checkbox
                    class="px-4"
                    v-model="consolidateFees"
                    label="Consolidate Fees When Printing"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <rules-text
                    v-model="totalFees"
                    readonly
                    class="dense-filled"
                    type="number"
                    label="Total Fees"
                    prepend-icon="mdi-currency-usd"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <rules-text
                    :value="netValue"
                    readonly
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                    type="number"
                    label="Net Value"
                    prepend-icon="mdi-currency-usd"
                    hint="Unpaid Value + Metal Value - Fees"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  offset-md="6"
                >
                  <rules-text
                    validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                    v-model="brokerageFee"
                    type="number"
                    label="Brokerage Fee"
                    prepend-icon="mdi-currency-usd"
                  />
                  <div class="text-center text-sm-body-2">
                    <a @click="brokerageFee = termBrokerageFee">
                      Use fee from terms {{ termBrokerageFee }}
                    </a>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <rules-text
                    :value="netValueLessBrokerage"
                    readonly
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                    type="number"
                    label="Net Value (Less Brokerage Fee)"
                    prepend-icon="mdi-currency-usd"
                    hint="Unpaid Value + Metal Value - Fees"
                  />
                </v-col>
              </v-row>
            </v-row>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Brokerage Terms
          </h2>
          Terms are displayed for reference only
          <v-sheet>
            <v-simple-table>
              <tbody>
                <tr
                  v-for="term in brokerageTerms"
                  :key="term[0]"
                >
                  <th>{{ term[0] }}</th>
                  <td
                    class="text-right"
                  >
                    {{ term[1] }}
                  </td>
                  <td
                    class="text-right"
                  >
                    {{ term[2] }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Finance / Amount Due
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <rules-date
                  v-model="expectedPaymentDate"
                  label="Expected Payment Date"
                  prepend-icon="mdi-calendar"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  readonly
                  class="dense-filled"
                  v-model="daysOfInterest"
                  label="Days of Interest"
                  type="number"
                  hint="Client Value Date - Expected Payment Date"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="interestPercent"
                  label="Interest Rate %"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="financeCharge"
                  class="dense-filled"
                  readonly
                  label="Finance Charge"
                  type="number"
                  hint="Net Value * Interest Percent / 360 * Days of Interest"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                offset-md="9"
                md="3"
              >
                <rules-text
                  :value="settlementAmountDue"
                  readonly
                  class="dense-filled"
                  label="Est Settlement Amount Due"
                  type="number"
                  hint="Net Value + Finance Charge"
                />
              </v-col>
              <v-col
                cols="12"
                offset-md="6"
                md="3"
              >
                <rules-text
                  v-model="depositPercent"
                  label="Deposit %"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="depositTotal"
                  class="dense-filled"
                  readonly
                  label="Deposit Total"
                  type="number"
                  hint="Settlement Amount * Deposit Percent"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Advances
          </h2>
          Advances will be saved separately to the advance calculator, and will be consistent across revisions.
          <v-sheet class="pa-4">
            <v-row
              v-for="advance in advances"
              :key="advance.id"
            >
              <v-col
                cols="12"
                md="9"
                class="mt-8"
              >
                <router-link
                  :to="{ name: 'advance-view', params: { id: advance.id } }"
                  target="_blank"
                >
                  Advance #{{ advance.id }}
                </router-link>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  readonly
                  class="dense-filled"
                  :label="`Advance #${ advance.id } Amount`"
                  :value="advance.amount_fx"
                  :hint="`Source ${advance.currency} ${advance.amount}`"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="9"
              >
                Recommended Advance is {{ recommendedAdvance }}. Click add advance to add advance entry.
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="text-right"
              >
                <v-btn
                  text
                  color="primary"
                  rounded
                  small
                  :to="{
                    name: 'advance-create',
                    query: advanceQuery
                  }"
                >
                  Add Advance <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                offset-md="9"
                md="3"
              >
                <rules-text
                  readonly
                  class="dense-filled"
                  label="Total Advances"
                  :value="totalAdvances"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-container>
        <text-fab
          bottom
          right
          fixed
          icon-text="mdi-content-save-outline"
          :color="isModified ? 'primary': ''"
          @click="save"
        >
          Save Changes
        </text-fab>
      </v-form>
    </ValidationObserver>
  </unauthorized>
</template>

<script>

import BrokeredSettlementSkeleton from '@/views/Lots/components/Brokered/components/BrokeredSettlementSkeleton.vue'
import RulesText from '@/components/ValidationFields/RulesText.vue'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import EditMetal from '@/views/Lots/components/Brokered/components/EditMetal.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AdvanceCalculator',
  components: {
    EditMetal,
    RulesDate,
    RulesText,
    BrokeredSettlementSkeleton
  },
  data () {
    return {
      isModified: false,
      selectedHedge: null,
      breadcrumbs: [
        ['lot list', { name: 'lot-list' }],
        [`Lot #${this.$route.params.id}`, { name: 'lot' }],
        [`Advanced Calculator`, { name: 'advance-calculator' }]
      ],
      yardChoices: [],
      loading: false,
      readonly: true
    }
  },
  watch: {
    yardId () {
      this.$store.dispatch('advancedCalculatorStore/fetchYardTerms')
    },
    snapshot () {
      this.isModified = true
    }
  },
  computed: {
    advanceQuery () {
      return {
        lot: this.lotId,
        amount: this.recommendedAdvance,
        redirect: JSON.stringify({
          name: 'advance-calculator',
          params: { id: this.lotId }
        })
      }
    },
    snapshot () {
      return [
        this.$store.getters['advancedCalculatorStore/getStateSnapshot']
      ]
    },
    yardId: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getYardId']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setYardId', value)
      }
    },
    shipDate: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getShipDate']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setShipDate', value)
      }
    },
    convertersProcessed: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getConvertersProcessed']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setConvertersProcessed', value)
      }
    },
    mixedCatalystWeight: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getMixedCatalystWeight']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setMixedCatalystWeight', value)
      }
    },
    clientValueDate: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getClientValueDate']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setClientValueDate', value)
      }
    },
    assayFee: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getAssayFee']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setAssayFee', value)
      }
    },
    shreddingFee: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getShreddingFee']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setShreddingFee', value)
      }
    },
    brokerageFee: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getBrokerageFee']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setBrokerageFee', value)
      }
    },
    refiningFee: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getRefiningFee']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setRefiningFee', value)
      }
    },
    processingFee: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getProcessingFee']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setProcessingFee', value)
      }
    },
    handlingFee: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getHandlingFee']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setHandlingFee', value)
      }
    },
    freightCharge: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getFreightCharge']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setFreightCharge', value)
      }
    },
    netOtherFees: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getNetOtherFees']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setNetOtherFees', value)
      }
    },
    consolidateFees: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getConsolidateFees']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setConsolidateFees', value)
      }
    },
    expectedPaymentDate: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getExpectedPaymentDate']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setExpectedPaymentDate', value)
      }
    },
    interestPercent: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getInterestPercent']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setInterestPercent', value)
      }
    },
    depositPercent: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getDepositPercent']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setDepositPercent', value)
      }
    },
    allocations () {
      return this.$store.getters['advancedCalculatorStore/getAllocations'].map(allocation => ({
        hedgeId: allocation.hedgeId,
        pt_returnable: allocation.ptToz,
        pt_return_rate: allocation.ptRate,
        pt_client_returnable: allocation.ptRtoz,
        pt_mk: allocation.ptMarketRate,
        pt_value: allocation.ptValue,
        pd_returnable: allocation.pdToz,
        pd_return_rate: allocation.pdRate,
        pd_client_returnable: allocation.pdRtoz,
        pd_mk: allocation.pdMarketRate,
        pd_value: allocation.pdValue,
        rh_returnable: allocation.rhToz,
        rh_return_rate: allocation.rhRate,
        rh_client_returnable: allocation.rhRtoz,
        rh_mk: allocation.rhMarketRate,
        rh_value: allocation.rhValue
      }))
    },
    containedPt: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getContainedPt']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setContainedPt', value)
      }
    },
    containedPd: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getContainedPd']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setContainedPd', value)
      }
    },
    containedRh: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getContainedRh']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setContainedRh', value)
      }
    },
    brokeredRatePt: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getBrokeredRatePt']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setBrokeredRatePt', value)
      }
    },
    brokeredRatePd: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getBrokeredRatePd']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setBrokeredRatePd', value)
      }
    },
    brokeredRateRh: {
      get () {
        return this.$store.getters['advancedCalculatorStore/getBrokeredRateRh']
      },
      set (value) {
        this.$store.commit('advancedCalculatorStore/setBrokeredRateRh', value)
      }
    },
    ...mapGetters('advancedCalculatorStore', {
      termsRefiningFeeTotal: 'getTermsRefiningFeeTotal',
      termsTreatmentCharge: 'getTermsTreatmentCharge',
      termsHandlingFee: 'getTermsHandlingFee',
      advances: 'getAdvances',
      allocatedPd: 'getAllocatedPd',
      allocatedPt: 'getAllocatedPt',
      allocatedRh: 'getAllocatedRh',
      allocatedReturnablePd: 'getAllocatedReturnablePd',
      allocatedReturnablePt: 'getAllocatedReturnablePt',
      allocatedReturnableRh: 'getAllocatedReturnableRh',
      availablePd: 'getAvailablePd',
      availablePt: 'getAvailablePt',
      availableRh: 'getAvailableRh',
      brokerageTerms: 'getCleanedBrokerageTerms',
      daysOfInterest: 'getDaysOfInterest',
      depositTotal: 'getDepositTotal',
      estimatedWeightFromPieces: 'getEstimatedWeightFromPieces',
      financeCharge: 'getFinanceCharge',
      grossMetalValue: 'getGrossMetalValue',
      grossOutrightUnpaidValue: 'getGrossOutrightUnpaidValue',
      history: 'getAdvanceCalculatorHistory',
      historyVersion: 'getHistoryVersion',
      lotId: 'getLotId',
      lotName: 'getLotName',
      netValue: 'getNetValue',
      netValueLessBrokerage: 'getNetValueLessBrokerage',
      newAdvances: 'getNewAdvances',
      pcToWeightConversionRate: 'getPcToWeightConversionRate',
      estimatedPtCtoz: 'getEstimatedPtCtoz',
      estimatedPdCtoz: 'getEstimatedPdCtoz',
      estimatedRhCtoz: 'getEstimatedRhCtoz',
      recommendedAdvance: 'getRecommendedAdvance',
      settlementAmountDue: 'getSettlementAmountDue',
      totalAdvances: 'totalAdvances',
      totalFees: 'getTotalFees',
      unallocatedHedges: 'getUnallocatedHedges',
      unallocatedPt: 'getUnallocatedPt',
      unallocatedPd: 'getUnallocatedPd',
      unallocatedRh: 'getUnallocatedRh',
      termShreddingFee: 'getTermShreddingFee',
      termBrokerageFee: 'getTermBrokerageFee'
    })
  },
  methods: {
    historyLabelFormat (history) {
      let text = `${history.id}. ${moment(history.created_at).format('MM/DD/YYYY HH:mm:ss')} ${history.fx_currency}`
      if (this.history.length > 0 && history.id === this.history[0].id) text += ' (Latest)'
      return text
    },
    addSelectedHedge () {
      if (!this.selectedHedge) return
      const hedge = this.selectedHedge
      this.$store.commit('advancedCalculatorStore/addHedgeToAllocations', {
        hedgeId: hedge.id,
        ptToz: Math.min(+hedge.ptAvailableToz, +this.unallocatedPt),
        ptRate: 0.90,
        ptMarketRate: hedge.ptRate,
        pdToz: Math.min(+hedge.pdAvailableToz, +this.unallocatedPd),
        pdRate: 0.90,
        pdMarketRate: hedge.pdRate,
        rhToz: Math.min(+hedge.rhAvailableToz, +this.unallocatedRh),
        rhRate: 0.90,
        rhMarketRate: hedge.rhRate
      })
      this.selectedHedge = null
      this.isModified = true
    },
    updateAllocations (hedgeId, allocation) {
      let updatedAllocations = []
      for (const line of this.$store.getters['advancedCalculatorStore/getAllocations']) {
        if (line.hedgeId === hedgeId) {
          line.ptToz = allocation.pt_returnable
          line.ptRate = allocation.pt_return_rate
          line.ptRtoz = allocation.pt_client_returnable
          line.ptMarketRate = allocation.pt_mk
          line.ptValue = allocation.pt_value
          line.pdToz = allocation.pd_returnable
          line.pdRate = allocation.pd_return_rate
          line.pdRtoz = allocation.pd_client_returnable
          line.pdMarketRate = allocation.pd_mk
          line.pdValue = allocation.pd_value
          line.rhToz = allocation.rh_returnable
          line.rhRate = allocation.rh_return_rate
          line.rhRtoz = allocation.rh_client_returnable
          line.rhMarketRate = allocation.rh_mk
          line.rhValue = allocation.rh_value
        }
        updatedAllocations.push(line)
      }
      this.$store.commit('advancedCalculatorStore/setAllocations', updatedAllocations)
    },
    updateOutrightValue (material, key, value) {
      let updatedLines = []
      for (const line of this.$store.getters['advancedCalculatorStore/getPurchasedOutright']) {
        if (line.materialType === material) {
          line[key] = value
        }
        updatedLines.push(line)
      }
      this.$store.commit('advancedCalculatorStore/setPurchasedOutright', updatedLines)
    },
    removeAllocation (allocation) {
      this.$store.commit('advancedCalculatorStore/removeAllocation', allocation.hedgeId)
      this.isModified = true
    },
    save () {
      this.$store.dispatch('advancedCalculatorStore/saveAdvanceCalculator')
      this.isModified = false
    },
    async initialize () {
      return Promise.all([
        this.$store.dispatch('advancedCalculatorStore/fetchAdvanceCalculators'),
        this.$store.dispatch('advancedCalculatorStore/fetchDesignatedHedges'),
        this.$store.dispatch('advancedCalculatorStore/fetchAdvances')
      ])
    },
    setVersionFromHistoryById (id) {
      const history = this.history.find(h => h.id === id)
      if (history) {
        this.$store.commit('advancedCalculatorStore/setContextFromHistory', history)
        return true
      }
      return false
    },
    refreshData () {
      return this.$store.dispatch('advancedCalculatorStore/refreshDataFromLotData')
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isModified) {
      if (!window.confirm('Leave without saving?')) {
        return
      }
    }
    next()
  },
  /*
   * Fetch the history, if no history then fetch the latest lot seed data
   */
  async mounted () {
    this.loading = true
    this.$store.commit('advancedCalculatorStore/clearState')
    this.$store.commit('advancedCalculatorStore/setLotId', this.$route.params.id)
    await this.initialize()
    if (this.history.length) {
      this.$store.commit('advancedCalculatorStore/setContextFromHistory', this.history[0])
    } else {
      // if there is no history, then seed data
      await this.refreshData()
    }
    this.loading = false
  }
}
</script>
