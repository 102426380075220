const CURRENCY_USD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const ptColor = '#0068b9'
const pdColor = '#d00e00'
const rhColor = '#006104'

class HedgeContent {
  constructor ({ hedge, expiration, createdAt, designation, allocations, ptRate, pdRate, rhRate, yardFriendlyName }) {
    this.hedgeID = hedge.id
    this.expiration = expiration
    this.createdAt = createdAt
    this.createdByName = hedge.created_by.username
    this.designation = designation
    this.allocations = allocations
    this.smelterName = hedge.smelter.name
    this.divisionName = hedge.division.name
    this.memo = hedge.memo
    this.yardFriendlyName = yardFriendlyName
    this.ptRate = ptRate
    this.pdRate = pdRate
    this.rhRate = rhRate
    this.ptInitial = hedge.pt_initial_toz
    this.pdInitial = hedge.pd_initial_toz
    this.rhInitial = hedge.rh_initial_toz
    this.ptRemaining = hedge.pt_estimated_toz
    this.pdRemaining = hedge.pd_estimated_toz
    this.rhRemaining = hedge.rh_estimated_toz
    this.ptReference = hedge.pt_reference
    this.pdReference = hedge.pd_reference
    this.rhReference = hedge.rh_reference
  }
  // Creates the details of the hedge
  setHeader () {
    let header = [
      {
        text: `Hedge List / Hedge #${this.hedgeID}\n\n`
      },
      {
        text: `Hedge ID #${this.hedgeID}\n\n`,
        style: 'subheading'
      },
      {
        text: `${this.divisionName}-${this.designation}-${this.smelterName}`
      },
      {
        text: `Created - ${this.createdAt}`
      },
      {
        text: `Expiration - ${this.expiration}`
      },
      {
        text: `Created By - ${this.createdByName}`,
        margin: [0, 0, 0, 20]
      }
    ]
    if (this.yardFriendlyName) {
      header.splice(3, 0, { text: `Hedging for ${this.yardFriendlyName}` })
    }
    return header
  }
  // creates the hedged metals details
  setTable () {
    return [
      {
        columns: [
          { width: 75, text: '' },
          {
            table: {
              widths: [120, 83, 83, 83],
              body: [
                [
                  { text: '', alignment: 'right' },
                  { text: 'Platinum', alignment: 'right', color: ptColor },
                  { text: 'Palladium', alignment: 'right', color: pdColor },
                  { text: 'Rhodium', alignment: 'right', color: rhColor }
                ],
                [
                  { text: 'Hedged ID', alignment: 'right' },
                  { text: this.ptReference, alignment: 'right', color: ptColor },
                  { text: this.pdReference, alignment: 'right', color: pdColor },
                  { text: this.rhReference, alignment: 'right', color: rhColor }
                ],
                [
                  { text: 'Hedged Metals', alignment: 'right' },
                  { text: this.ptInitial, alignment: 'right', color: ptColor },
                  { text: this.pdInitial, alignment: 'right', color: pdColor },
                  { text: this.rhInitial, alignment: 'right', color: rhColor }
                ],
                [
                  { text: 'Hedged Prices', alignment: 'right' },
                  { text: this.formatCurrency(this.ptRate), alignment: 'right', color: ptColor },
                  { text: this.formatCurrency(this.pdRate), alignment: 'right', color: pdColor },
                  { text: this.formatCurrency(this.rhRate), alignment: 'right', color: rhColor }
                ],
                [
                  { text: 'Hedged Metal Values', alignment: 'right' },
                  { text: this.getHedgeValues(this.ptRate, this.ptInitial), alignment: 'right', color: ptColor },
                  { text: this.getHedgeValues(this.pdRate, this.pdInitial), alignment: 'right', color: pdColor },
                  { text: this.getHedgeValues(this.rhRate, this.rhInitial), alignment: 'right', color: rhColor }
                ]
              ]
            },
            layout: 'noBorders'
          }
        ]
      }
    ]
  }
  // creates the lot allocations for the hedged metals
  setAllocations () {
    return [
      {
        columns: [
          { width: 75, text: '' },
          {
            table: {
              widths: [118, 80, 80, 81],
              body: [
                [
                  { text: 'Allocations', alignment: 'right' },
                  { text: 'Platinum', alignment: 'right', color: ptColor },
                  { text: 'Palladium', alignment: 'right', color: pdColor },
                  { text: 'Rhodium', alignment: 'right', color: rhColor }
                ],
                ...this.getLotAllocations(),
                [
                  { text: 'Remaining', alignment: 'right' },
                  { text: this.ptRemaining, alignment: 'right', color: ptColor },
                  { text: this.pdRemaining, alignment: 'right', color: pdColor },
                  { text: this.rhRemaining, alignment: 'right', color: rhColor }
                ]
              ]
            }
          }
        ]
      }
    ]
  }
  // creates the ntoes section of the pdf
  setNotes () {
    return [
      {
        text: 'Notes',
        margin: [0, 20, 0, 0]
      },
      {
        text: this.memo
      }
    ]
  }
  // gets the metal allocations for each lot
  getLotAllocations () {
    return this.allocations.map(e => [
      { text: e.lot.name, alignment: 'right' },
      { text: e.pt, alignment: 'right', color: ptColor },
      { text: e.pd, alignment: 'right', color: pdColor },
      { text: e.rh, alignment: 'right', color: rhColor }
    ])
  }
  // puts all the components of the document together
  getTable () {
    return [this.setHeader(), this.setTable(), this.setAllocations(), this.setNotes()]
  }
  // multiplies the metal price by the qty and formats the value into a currency
  getHedgeValues (price, metalQty) {
    return this.formatCurrency(+price * +metalQty)
  }
  // function to format a number into a currency
  formatCurrency (value) {
    return CURRENCY_USD.format(value)
  }
}

// creates the pdf document
export const hedgeDocDef = config => (new HedgeContent(config)).getTable()
