import axios from 'axios'

const getDefaultState = () => {
  return {
    hasProforma: true,
    isLocked: true,
    historyVersion: null,
    history: [],

    yardChoices: [],
    lotName: 'N/A',
    lotId: null,
    yardId: null,
    customerReference: null,
    dateReceived: null,
    metalValueDate: null,
    totalUnits: null,
    grossReceived: null,
    scrapWeight: null,
    percentMoisture: null,
    netWetReceived: null,
    netDryWeight: null,
    ptPPM: null,
    ptToz: null,
    ptReturnRate: null,
    pdPPM: null,
    pdToz: null,
    pdReturnRate: null,
    rhPPM: null,
    rhToz: null,
    rhReturnRate: null,
    labFee: null,
    treatmentCharge: null,
    refiningFee: null,
    shreddingFee: null,
    lotCharge: null,
    smallLotFee: null,
    showAssays: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getIsLocked: (state) => state.isLocked,
    getStateSnapshot: (state, getters) => {
      return {
        lot: state.lotId,
        data: {
          yardChoices: state.yardChoices,
          lotName: state.lotName,
          lotId: state.lotId,
          yardId: state.yardId,
          customerReference: state.customerReference,
          dateReceived: state.dateReceived,
          metalValueDate: state.metalValueDate,
          totalUnits: state.totalUnits,
          grossReceived: state.grossReceived,
          scrapWeight: state.scrapWeight,
          percentMoisture: state.percentMoisture,
          netWetReceived: state.netWetReceived,
          netDryWeight: state.netDryWeight,
          ptPPM: state.ptPPM,
          ptToz: state.ptToz,
          ptReturnRate: state.ptReturnRate,
          pdPPM: state.pdPPM,
          pdToz: state.pdToz,
          pdReturnRate: state.pdReturnRate,
          rhPPM: state.rhPPM,
          rhToz: state.rhToz,
          rhReturnRate: state.rhReturnRate,
          labFee: state.labFee,
          treatmentCharge: state.treatmentCharge,
          refiningFee: state.refiningFee,
          shreddingFee: state.shreddingFee,
          lotCharge: state.lotCharge,
          smallLotFee: state.smallLotFee,
          showAssays: state.showAssays,

          totalOfCharges: getters.getTotalOfCharges
        }
      }
    },
    getPtTotal: (state) => {
      return (state.ptReturnRate / 100 * state.ptToz).toFixed(2)
    },
    getPdTotal: (state) => {
      return (state.pdReturnRate / 100 * state.pdToz).toFixed(2)
    },
    getRhTotal: (state) => {
      return (state.rhReturnRate / 100 * state.rhToz).toFixed(2)
    },
    getTotalOfCharges: (state, getters) => {
      return +state.labFee +
        +state.treatmentCharge +
        +state.refiningFee +
        +state.shreddingFee +
        +state.lotCharge +
        +state.smallLotFee
    }
  },
  mutations: {
    setHasProforma: (state, value) => {
      state.hasProforma = value
    },
    setIsLocked: (state, value) => {
      state.isLocked = value
    },
    setHistoryVersionId (state, value) {
      state.historyVersion = value
    },
    setHistory (state, value) {
      state.history = value
    },
    clearState (state) {
      Object.assign(state, getDefaultState())
    },
    // sets the local variables based on a history entry
    setContextFromHistory (state, value) {
      if ('data_fx' in value) {
        state.historyVersion = value.id
        for (const prop of Object.keys(value.data_fx)) {
          if (prop in state) state[prop] = value.data_fx[prop]
        }
      }
    },
    setYardChoices (state, value) {
      state.yardChoices = value
    },
    setYardId (state, value) {
      state.yardId = value
    },
    setLotName (state, value) {
      state.lotName = value
    },
    setLotId (state, value) {
      state.lotId = value
    },
    setCustomerReference (state, value) {
      state.customerReference = value
    },
    setDateReceived (state, value) {
      state.dateReceived = value
    },
    setMetalValueDate (state, value) {
      state.metalValueDate = value
    },
    setTotalUnits (state, value) {
      state.totalUnits = value
    },
    setGrossReceived (state, value) {
      state.grossReceived = value
    },
    setScrapWeight (state, value) {
      state.scrapWeight = value
    },
    setPercentMoisture (state, value) {
      state.percentMoisture = value
    },
    setNetWetReceived (state, value) {
      state.netWetReceived = value
    },
    setNetDryWeight (state, value) {
      state.netDryWeight = value
    },
    setPtPPM (state, value) {
      state.ptPPM = value
    },
    setPtToz (state, value) {
      state.ptToz = value
    },
    setPtReturnRate (state, value) {
      state.ptReturnRate = value
    },
    setPdPPM (state, value) {
      state.pdPPM = value
    },
    setPdToz (state, value) {
      state.pdToz = value
    },
    setPdReturnRate (state, value) {
      state.pdReturnRate = value
    },
    setRhPPM (state, value) {
      state.rhPPM = value
    },
    setRhToz (state, value) {
      state.rhToz = value
    },
    setRhReturnRate (state, value) {
      state.rhReturnRate = value
    },
    setLabFee (state, value) {
      state.labFee = value
    },
    setTreatmentCharge (state, value) {
      state.treatmentCharge = value
    },
    setRefiningFee (state, value) {
      state.refiningFee = value
    },
    setShreddingFee (state, value) {
      state.shreddingFee = value
    },
    setLotCharge (state, value) {
      state.lotCharge = value
    },
    setSmallLotFee (state, value) {
      state.smallLotFee = value
    },
    setShowAssays (state, value) {
      state.showAssays = value
    }
  },
  actions: {
    async fetchShipmentResults ({ commit, state }) {
      const response = await axios.get('/api/shipment_results/', { params: { lot: state.lotId } })
      commit('setHistory', response.data.results)
    },
    // Gets the current advance calculator context and saves a new entry
    async saveShipmentResult ({ commit, state, getters }) {
      const payload = getters.getStateSnapshot
      const response = await axios.post('/api/shipment_results/', payload)
      if (response.data) {
        commit('setHistory', [response.data, ...state.history])
        commit('setHistoryVersionId', response.data.id)
      }
    },
    async refreshDataFromLotData ({ commit, state, dispatch }) {
      // Fetch lot seed data to populate the report fields
      // LOT ID should already be set
      const response = await axios.get(`/api/lots/${state.lotId}/shipment_result_seed/`)
      if (response.data) {
        const rd = response.data
        if ('hasProforma' in rd) commit('setHasProforma', rd.hasProforma)
        if ('yardChoices' in rd) commit('setYardChoices', rd.yardChoices)
        if ('lotName' in rd) commit('setLotName', rd.lotName)
        if ('lotId' in rd) commit('setLotId', rd.lotId)
        if ('customerReference' in rd) commit('setCustomerReference', rd.customerReference)
        if ('dateReceived' in rd) commit('setDateReceived', rd.dateReceived)
        if ('metalValueDate' in rd) commit('setMetalValueDate', rd.metalValueDate)
        if ('totalUnits' in rd) commit('setTotalUnits', rd.totalUnits)
        if ('grossReceived' in rd) commit('setGrossReceived', rd.grossReceived)
        if ('scrapWeight' in rd) commit('setScrapWeight', rd.scrapWeight)
        if ('percentMoisture' in rd) commit('setPercentMoisture', rd.percentMoisture)
        if ('netWetReceived' in rd) commit('setNetWetReceived', rd.netWetReceived)
        if ('netDryWeight' in rd) commit('setNetDryWeight', rd.netDryWeight)
        if ('ptPPM' in rd) commit('setPtPPM', rd.ptPPM)
        if ('ptToz' in rd) commit('setPtToz', rd.ptToz)
        if ('ptReturnRate' in rd) commit('setPtReturnRate', rd.ptReturnRate)
        if ('pdPPM' in rd) commit('setPdPPM', rd.pdPPM)
        if ('pdToz' in rd) commit('setPdToz', rd.pdToz)
        if ('pdReturnRate' in rd) commit('setPdReturnRate', rd.pdReturnRate)
        if ('rhPPM' in rd) commit('setRhPPM', rd.rhPPM)
        if ('rhToz' in rd) commit('setRhToz', rd.rhToz)
        if ('rhReturnRate' in rd) commit('setRhReturnRate', rd.rhReturnRate)
        if ('labFee' in rd) commit('setLabFee', rd.labFee)
        if ('treatmentCharge' in rd) commit('setTreatmentCharge', rd.treatmentCharge)
        if ('refiningFee' in rd) commit('setRefiningFee', rd.refiningFee)
        if ('shreddingFee' in rd) commit('setShreddingFee', rd.shreddingFee)
        if ('lotCharge' in rd) commit('setLotCharge', rd.lotCharge)
        if ('smallLotFee' in rd) commit('setSmallLotFee', rd.smallLotFee)
        if ('showAssays' in rd) commit('setShowAssays', rd.showAssays)

        commit('setHistoryVersionId', null)
        // These are normally automatically fetched when a yardId is changed in the view - no change no fetch
      }
    }
  }
}
