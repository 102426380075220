<template>
  <unauthorized
    :authorized="$store.getters.can('view-partial-lots')"
    message="Unauthorized to view Lots"
  >
    <base-title title="Lot List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchAdvancedSets"
    >
      <date-range-input
        :start-date="createdStartDate"
        :end-date="createdEndDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="createdStartDate = $event"
        @alterEndDate="createdEndDate = $event"
      />
      <date-range-input
        :start-date="shipStartDate"
        :end-date="shipEndDate"
        start-date-label="Ship Date From"
        end-date-label="Ship Date To"
        @alterStartDate="shipStartDate = $event"
        @alterEndDate="shipEndDate = $event"
      />
      <date-range-input
        :start-date="valueStartDate"
        :end-date="valueEndDate"
        start-date-label="Value Date From"
        end-date-label="Value Date To"
        @alterStartDate="valueStartDate = $event"
        @alterEndDate="valueEndDate = $event"
      />
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Smelter Lot Id"
            v-model="smelterLotId"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            label="Notes"
            v-model="notes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Division"
            multiple
            v-model="divisionSet"
            :items="divisions"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Designation"
            multiple
            v-model="designationSet"
            :items="lotDesignations"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Smelter"
            multiple
            v-model="smelterSet"
            :items="smelters"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Statuses"
            multiple
            v-model="statusSet"
            :items="lotStatuses"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Entity"
            v-model="entity"
            :items="entities"
            item-text="name"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Material Types"
            v-model="typeSet"
            multiple
            :items="$store.getters['getLotTypes']"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:item.name="{ item }">
        <router-link-id
          :id="item.id"
          name="lot"
          :display="item.name"
        />
        <div
          v-if="item.parent_data"
          class="pl-2"
        >
          <v-chip
            outlined
            x-small
          >
            {{ item.parent_data.name }} - primary
          </v-chip>
        </div>
        <div
          v-if="item.children_data"
          class="pl-2 d-flex flex-column align-start"
        >
          <v-chip
            v-for="(child, index) in item.children_data"
            :key="index"
            outlined
            x-small
          >
            {{ child.name }} - sub
          </v-chip>
        </div>
      </template>
      <template v-slot:item.code="{ item }">
        <span style="white-space: nowrap;">
          {{ item.code }}
        </span>
      </template>
      <template v-slot:item.designation="{ item }">
        {{ formatDesignation(item.designation, lotDesignations) }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ formatLotStatus(item.status, lotStatuses) }}
      </template>
      <template v-slot:item.ship_date="{ item }">
        {{ item.ship_date | formatDate | filterNA }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate | filterNA }}
      </template>
      <template v-slot:item.shipment_summary_lot__value_date="{ item }">
        {{ item.shipment_summary_lot__value_date | formatDate | filterNA }}
      </template>
      <template v-slot:item.cut_plan_color="{ item }">
        <cut-plan-view-button
          :id="item.id"
          :color="item.cut_plan_color"
        />
      </template>
      <template v-slot:item.shipment_summary_lot="{ item }">
        <v-icon
          v-if="item.shipment_summary_lot"
          small
          color="green lighten-3"
        >
          mdi-circle
        </v-icon>
      </template>
      <template v-slot:item.purchase_allocations_complete="{ item }">
        <v-icon
          v-if="item.purchase_allocations_complete"
          small
          color="green lighten-3"
        >
          mdi-circle
        </v-icon>
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-lots')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="$router.push({ name: 'create-lot' })"
    >
      Create Lot
    </text-fab>
    <div class="py-8" />
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
import { mapGetters } from 'vuex'
import CutPlanViewButton from '@/components/CutPlanViewButton'
import { fetchEntities } from '@/requests/entities'

export default {
  mixins: [chipsMixin, paginationMixin],

  name: 'LotList',
  components: {
    CutPlanViewButton,
    ResultPagination,
    SearchFilters
  },
  data: function () {
    let additionalHeaders = []
    if (this.$store.getters.can('view-cut-plans')) {
      additionalHeaders.push({ text: 'Cut Plan', value: 'cut_plan_color' })
    }
    return {
      pageName: 'lot-list',

      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Entity', value: 'entity_code' },
        { text: 'Code', value: 'code' },
        { text: 'Notes', value: 'notes' },
        { text: 'Status', value: 'status' },
        { text: 'Summary', value: 'shipment_summary_lot' },
        { text: 'Ship Date', value: 'ship_date' },
        { text: 'Value Date', value: 'shipment_summary_lot__value_date' },
        { text: 'Created Date', value: 'created_at' },
        { text: 'Purchase Allocations Final', value: 'purchase_allocations_complete' },
        ...additionalHeaders
      ],

      ...this.chipPropagation([
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'Notes', prop: 'notes', type: String, query: 'notes__icontains' },
        { name: 'SmelterLotId', prop: 'smelterLotId', type: String, query: 'smelter_lot_id__icontains' },

        { name: 'Material Type', prop: 'typeSet', type: Array, query: 'lot_type__in' },
        { name: 'Designation', prop: 'designationSet', type: Array, query: 'designation__in' },

        { name: 'Divisions', prop: 'divisionSet', type: Array, query: 'division__code__in' },
        { name: 'Smelters', prop: 'smelterSet', type: Array, query: 'smelter__name__in' },
        { name: 'Status', prop: 'statusSet', type: Array, query: 'status__in' },
        { name: 'Entity', prop: 'entity', type: String, query: 'entity__code' },
        { name: 'Start Ship Date', prop: 'shipStartDate', type: String, query: 'ship_date__gte' },
        { name: 'End Ship Date', prop: 'shipEndDate', type: String, query: 'ship_date__lte' },
        { name: 'Start Create Date', prop: 'createdStartDate', type: String, query: 'created_at__gte' },
        { name: 'End Create Date', prop: 'createdEndDate', type: String, query: 'created_at__lte' },
        { name: 'Start Value Date', prop: 'valueStartDate', type: String, query: 'shipment_summary_lot__value_date__gte' },
        { name: 'End Value Date', prop: 'valueEndDate', type: String, query: 'shipment_summary_lot__value_date__lte' }
      ]),

      divisions: [],
      smelters: [],
      entities: []
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  computed: {
    ...mapGetters({
      lotDesignations: 'lotStore/lotTypes',
      lotStatuses: 'lotStore/lotStatuses'
    })
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        // 'url',
        'id',
        'code',
        // 'inventory',
        // 'smelter',
        'name',
        'status',
        'designation',
        'ship_date',
        // 'piece_count',
        'lot_type',
        // 'estimated_pt',
        // 'estimated_pd',
        // 'estimated_rh',
        // 'estimated_returnable_pt',
        // 'estimated_returnable_pd',
        // 'estimated_returnable_rh',
        // 'contained_pt',
        // 'contained_pd',
        // 'contained_rh',
        // 'returnable_pt',
        // 'returnable_pd',
        // 'returnable_rh',
        'notes',
        // 'outbound_transit',
        'shipment_summary_lot',
        'shipment_summary_lot__value_date',
        // 'ppm_calculator',
        // 'allocations',
        'cut_plan_color',
        'created_at',
        // 'yard'
        'children_data',
        'parent_data',
        'entity_code',
        'purchase_allocations_complete'
      ].join(',')
      axios.get('/api/lots/', { params }).then(this.setResponseResults)
    },
    fetchAdvancedSets () {
      if (this.divisions.length === 0) {
        axios.get('/api/divisions/?page_size=0&fields=name,code').then(response => {
          this.divisions = response.data.map(d => ({ text: d.name, value: d.code }))
        })
      }
      if (this.smelters.length === 0) {
        axios.get('/api/smelters/?page_size=0&fields=id,name').then(r => {
          this.smelters = r.data.map(sm => ({ text: sm.name, value: sm.name }))
        })
      }
      if (this.entities.length === 0) {
        fetchEntities().then(response => {
          if (response.data) this.entities = response.data
        })
      }
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
