<template>
  <v-card>
    <v-expansion-panels
      accordion
      @change="loadCheckIns"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="v-card__subtitle font-weight-bold">
          <div>
            Intakes & Check-Ins w/ Unlinked Material
            <span class="caption">(Converted to {{ $auth.user.fx_currency }})</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <lot-check-ins-table
            :loading="loading"
            :check-ins="checkIns"
            :totals="totals"
          >
            <template v-slot:additional-actions>
              <info-tooltip>
                <template v-slot:wrap-me>
                  <v-btn
                    small
                    text
                    @click="toggleComplete"
                  >
                    <span v-if="!completed">
                      Allocations Final <v-icon small>mdi-checkbox-blank-outline</v-icon>
                    </span>
                    <span v-else>
                      Allocations Final <v-icon small>mdi-checkbox-marked-outline</v-icon>
                    </span>
                  </v-btn>
                </template>
                Denotes that the Purchase Allocations are complete.
              </info-tooltip>
            </template>
          </lot-check-ins-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import axios from 'axios'
import LotCheckInsTable from './LotCheckInsTable'
import { patchLotAllocationsComplete } from '@/requests/lot'

export default {
  name: 'IntakeCheckInSectionLooselyRelated',
  components: {
    LotCheckInsTable
  },
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      completed: true,
      panels: [],
      loading: true,
      intakes: [],
      checkIns: {},
      totals: {},
      fetched: false
    }
  },
  methods: {
    toggleComplete () {
      // Updates the current lot to reflect that the purchase lots are complete
      patchLotAllocationsComplete(this.lot.id, !this.completed).then(value => {
        this.completed = value
      }).catch(() => {
        this.$store.commit('setSnackbarError', 'Unable to update Purchase Allocations Complete Value')
      })
    },
    async loadCheckIns () {
      if (!this.fetched) {
        const response = await axios.get(`/api/lots/${this.lot.id}/check_ins_loosely_related/`)
        if (response.data) {
          this.checkIns = response.data.check_ins
          this.totals = response.data.totals
        }
        this.loading = false
      }
    }
  },
  mounted () {
    this.completed = this.lot.purchase_allocations_complete
  }
}
</script>
