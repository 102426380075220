var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateLot($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('edit-dialog',{attrs:{"value":_vm.value,"inputs-valid":_vm.valid && (!_vm.statusIsChangedMessage || _vm.confirmStatusChange) && !_vm.canNotDissolve,"disabled":invalid,"headline-text":"Edit Lot"},on:{"input":function($event){return _vm.$emit('input', $event)},"accept":_vm.updateLot}},[_c('v-expand-transition',[(_vm.loading)?_c('app-loading-indicator'):_c('div',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.lotFinalized,"items":_vm.lotStatuses,"label":"Status","error-messages":errors},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}],null,true)}),(_vm.canNotDissolve)?_c('v-alert',{attrs:{"type":"warning","color":"warning","outlined":"","dense":""}},[_vm._v(" Unable to DISSOLVE lots with transit or hedge allocations ")]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.lotFinalized,"items":_vm.entities,"item-value":"url","item-text":"name","label":"Entity","error-messages":errors},model:{value:(_vm.entityUrl),callback:function ($$v) {_vm.entityUrl=$$v},expression:"entityUrl"}})]}}],null,true)}),_c('v-expand-transition',[(_vm.statusIsChangedMessage)?_c('ValidationProvider',{attrs:{"rules":"required","type":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":_vm.statusIsChangedMessage,"error-messages":errors},model:{value:(_vm.confirmStatusChange),callback:function ($$v) {_vm.confirmStatusChange=$$v},expression:"confirmStatusChange"}})]}}],null,true)}):_vm._e()],1),_vm._v(" Primary lot "),(_vm.lot.children_data.length)?_c('div',[_c('em',[_vm._v("this lot is a primary lot")])]):(_vm.lot.parent)?_c('div',[_c('em',[_vm._v("this lot is already a child lot")]),_c('v-checkbox',{attrs:{"label":"disconnect from parent lot"},model:{value:(_vm.disconnectFromParent),callback:function ($$v) {_vm.disconnectFromParent=$$v},expression:"disconnectFromParent"}}),(_vm.disconnectFromParent)?_c('strong',{staticClass:"red--text"},[_vm._v(" Disconnecting from parent lot will clear the \"inventory lines\". They will need to be manually re-allocated off of the parent lot. ")]):_vm._e()],1):_c('div',[_c('div',[_c('strong',[_vm._v("Primary: "+_vm._s(_vm.lot.parent_data ? _vm.lot.parent_data.name : 'None'))]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.editParent = !_vm.editParent}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.editParent ? 'mdi-pencil-off' : 'mdi-pencil')+" ")])],1)],1),_c('v-expand-transition',[(_vm.editParent)?_c('div',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('search-autocomplete',{attrs:{"label":"Primary Lot (search)","api-path":"/api/lots/","params":{ fields: 'url,id,name', parent__isnull: true },"format-entry":function (l) { return Object.assign({ friendlyName: l.name }, l); },"clearable":""},model:{value:(_vm.parentLot),callback:function ($$v) {_vm.parentLot=$$v},expression:"parentLot"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"error--text"},[_vm._v(" "+_vm._s(err)+" ")])})]}}],null,true)})],1):_vm._e()])],1),_c('div',[_c('strong',[_vm._v("Account Manager: "+_vm._s(_vm.lot.account_manager__username || 'None'))]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.editAccountManager = !_vm.editAccountManager}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.editAccountManager ? 'mdi-pencil-off' : 'mdi-pencil')+" ")])],1)],1),_c('v-expand-transition',[(_vm.editAccountManager)?_c('div',[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('search-autocomplete',{attrs:{"label":"Account Manager","item-text":"username","item-value":"url","api-path":"/api/users/","params":{ fields: 'username,url', is_active: true },"error-messages":errors,"format-entry":function (x) { return Object.assign({ friendlyName: x.username }, x); }},model:{value:(_vm.accountManagerUrl),callback:function ($$v) {_vm.accountManagerUrl=$$v},expression:"accountManagerUrl"}})]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"my-4"}),_c('ValidationProvider',{attrs:{"rules":"required|max:55"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Lot Name","counter":"55","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('v-select',{attrs:{"label":"Smelter","item-text":"name","item-value":"id","items":_vm.$store.getters['smelterStore/smelters']},model:{value:(_vm.smelterId),callback:function ($$v) {_vm.smelterId=$$v},expression:"smelterId"}}),_c('v-text-field',{attrs:{"label":"Smelter Lot ID"},model:{value:(_vm.smelterLotId),callback:function ($$v) {_vm.smelterLotId=$$v},expression:"smelterLotId"}}),_c('v-select',{attrs:{"items":_vm.lotMaterialType,"label":"Lot Material Type"},model:{value:(_vm.lotType),callback:function ($$v) {_vm.lotType=$$v},expression:"lotType"}}),_c('v-select',{attrs:{"items":_vm.lotTypes,"label":"Designation"},model:{value:(_vm.designation),callback:function ($$v) {_vm.designation=$$v},expression:"designation"}}),_c('ValidationProvider',{attrs:{"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Manual Piece Count","error-messages":errors},model:{value:(_vm.pieceCount),callback:function ($$v) {_vm.pieceCount=$$v},expression:"pieceCount"}})]}}],null,true)}),_c('date-input',{attrs:{"label":"Processing Date"},model:{value:(_vm.processingDate),callback:function ($$v) {_vm.processingDate=$$v},expression:"processingDate"}}),_c('date-input',{attrs:{"label":"Ship date","hint":"The ship date value will be automatically updated by the outbound transit."},model:{value:(_vm.shipDate),callback:function ($$v) {_vm.shipDate=$$v},expression:"shipDate"}}),_c('date-input',{attrs:{"label":"Payment date","hint":"To be added when we have the results / make the settlement."},model:{value:(_vm.paymentDate),callback:function ($$v) {_vm.paymentDate=$$v},expression:"paymentDate"}}),_c('ValidationProvider',{attrs:{"rules":"max:280"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Notes","counter":"280","error-messages":errors},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})]}}],null,true)}),_c('br'),_c('info-tooltip',{scopedSlots:_vm._u([{key:"wrap-me",fn:function(){return [_c('v-btn',{attrs:{"disabled":!(_vm.lot.status === 'CO' && _vm.$store.getters.can('update-lot-status-back'))},on:{"click":_vm.pushBack}},[_vm._v(" Push back to Reconcile ")])]},proxy:true}],null,true)},[_vm._v(" Available when status is Complete. Requires 'update-lot-status-back' ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }